/** @format */

import React, { useState, useEffect } from 'react';
import ChatbotDrawer from './ChatbotDrawer';
import ChatbotRail from './ChatbotRail';
import ChatTip from './ChatTip';
import { Rnd } from 'react-rnd';
import { useDispatch } from 'react-redux';
import { addMessageToHistory } from '../redux/userSlice';
import prompts from '../assets/data/chat/prompts.json';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
const ChatbotContainer = (props) => {
	const [chatDrawerWasOpen, setChatDrawerWasOpen] = useState();
	const [position, setPosition] = useState({
		x: 0,
		y: 0,
		width: 380,
		height: '100%',
	});

	const dispatch = useDispatch();

	useEffect(() => {
		if (props.drawerMode === 'sidebyside') {
			setPosition({ x: 0, y: 0, width: 380, height: '100%' });
		} else {
			setPosition({
				x: window.innerWidth - 380 - 64,
				y: window.innerHeight * 0.3,
				width: 380,
				height: '70%',
			});
		}
	}, [props.drawerMode]);

	const getWelcomePrompt = () => {
		console.log('Get welcome prompt for when drawer closed');
		let currentID = props.item.itemid,
			currentPrompts = prompts.filter((f) => f.screenID === currentID);

		if (currentPrompts.length > 0) {
			let welcomePrompts = currentPrompts[0].scenarios.filter(
				(f) => f.type === 'load'
			);

			if (welcomePrompts[0].prompts.length > 0) {
				let messageToStore = {};
				welcomePrompts[0].prompts.forEach((prompt) => {
					setTimeout(() => {
						console.log(prompt);
						// setWelcomePrompt(prompt);
						dispatch(
							addMessageToHistory({
								itemId: currentID,
								itemNum: props.currentItem,
								itemName: props.item.name,
								user: 'welcome',
								text: prompt.question,
								popup: prompt.popup,
								buttons: prompt.buttons ? prompt.buttons : undefined,
								mcq: prompt.mcq ? prompt.mcq : undefined
							})
						);
					}, 1000);
				});
			}
		}
	};
	useEffect(() => {
		if (props.pageType === 'assignment') {
			getWelcomePrompt();
		}
		if (props.drawerOpen) {
			setChatDrawerWasOpen(true);
		} else {
			setChatDrawerWasOpen(false);
		}
	}, [props.item]);


	const CustomHandle = () => {
		return (<div className='customHandle' style={{width:40, height:40,background:'blue',zIndex:101, position:'relative', color:'white', padding:'8px'}}>
			<CallReceivedIcon/>
		</div>)
	}

	return (
		<>
			<div className='chatbot'>
				{props.drawerOpen ? (
					<>
						<div
							className={`chatbot-drawer-container		
					${props.drawerMode === 'floating' ? 'overlay' : 'sidebyside'}`}

							// style={props.drawerMode === 'sidebyside' ? {width: position.width} : {}}
						>
							<Rnd
								disableDragging={props.drawerMode === 'sidebyside'}
								enableResizing={
									props.drawerMode === 'sidebyside'
										? {
												top: false,
												right: false,
												bottom: false,
												left: true,
												topRight: false,
												bottomRight: false,
												bottomLeft: false,
												topLeft: false,
										  }
										: true
								}
								// default={{ x: position.x, y: position.y }}
								position={{ x: position.x, y: position.y }}
								size={{ width: position.width, height: position.height }}
								key={'mode' + props.drawerMode}
								minWidth={380}
								style={{ pointerEvents: 'auto' }}
								bounds={'.chatbot-drawer-container'}
								resizeHandleStyles={{
									bottomLeft: {height:40, width:40},
									bottomRight: {height:40, width:40},
									topLeft: {height:40,width:40},
									bottomRight: {height:40, width:40},
								}
								}
								// resizeHandleComponent={{
								// 	bottomLeft: <CustomHandle/>
								// }}
								onDragStop={(e, d) => {
									setPosition({ ...position, x: d.x, y: d.y });
								}}
								onResize={(e, direction, ref, delta, position) => {
									setPosition({
										width: ref.offsetWidth,
										height: ref.offsetHeight,
										...position,
									});
								}}
							>
								<ChatbotDrawer
									close={() => props.changeDrawerOpen(false)}
									drawerMode={props.drawerMode}
									changeDrawerMode={props.changeDrawerMode}
									pageType={props.pageType}
									item={props.item}
									drawerType={props.drawerType}
									assignmentComplete={props.assignmentComplete}
									gotoItem={props.gotoItem}
									currentItem={props.currentItem}
								/>
							</Rnd>
						</div>
					</>
				) : props.chatTipOpen &&
				  props.pageType !== 'overview' &&
				  !chatDrawerWasOpen ? (
					<ChatTip
						close={props.closeChatTip}
						hide={props.removeChatTip}
						hideChatTip={props.hideChatTip}
						open={() => props.changeDrawerOpen(true)}
					/>
				) : (
					<></>
				)}
			</div>
			<ChatbotRail
				drawerOpen={props.drawerOpen}
				changeDrawerType={props.changeDrawerType}
				openDrawer={(foo) => {
					props.changeDrawerOpen(true);
				}}
			/>
		</>
	);
};
export default ChatbotContainer;
