/** @format */

import PearsonLogo from './assets/images/logo_pearson.png';
import { IconButton } from '@mui/material/';
import Switch from './Switch';
import UserMenu from './UserMenu';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Header = (props) => {
	return (
		<header className='app-header'>
			<div className='left'>
				<img
					src={PearsonLogo}
					alt=''
				/>
				<h1>
					<span>Human Anatomy &amp; Physiology</span> <ExpandMoreIcon />
				</h1>
			</div>
			<div className='right'>
				{/*<label htmlFor='studentSwitch'>Student view</label>

				<Switch
					id='studentSwitch'
					checked={props.studentView}
					onChange={props.changeStudentView}
	/> */}
				<IconButton>
					<HelpOutlineIcon sx={{ width: '30px', height: '30px' }} />
				</IconButton>
				{/* <IconButton sx={{ ml: '-8px', position: 'relative' }}>
					<NotificationsNoneIcon sx={{ width: '30px', height: '30px' }} />
					<span className='badge'>3</span>
				</IconButton> */}

				<UserMenu />
			</div>
		</header>
	);
};

export default Header;
