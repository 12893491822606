/** @format */

import AssignmentCard from './AssignmentCard';
import { forwardRef, useEffect, useRef, useState } from 'react';

const PLayerNavigation = forwardRef((props, ref) => {
	let itemCount = -1;
	let scrollPos = props.currentItem;
	/*let completionPercentage = (props.itemsCompleted / props.totalItems) * 100;*/
	const navContainerRef = useRef(null);
	const navRef = useRef(null);
	const container = useRef(null);
	const [role, setRole] = useState('navigation');
	const [showArrows, setShowArrows] = useState(true);

	const handleScroll = () => {
		const navHeight = navRef.current.clientHeight;
		const itemHeight = navHeight / props.totalItems;

		var amtToScroll = itemHeight * scrollPos;

		navContainerRef.current.scrollTop = amtToScroll;
	};

	const handleNextBtn = () => {
		scrollPos++;
		handleScroll();
	};

	const handlePrevBtn = () => {
		scrollPos--;
		handleScroll();
	};

	useEffect(() => {
		needArrows();
		if (!props.navigationOpen) {
			setRole('toolbar');
		}
	}, [role, setRole, props]);

	const needArrows = () => {
		const navHeight = navRef.current.clientHeight;

		const heightOfAllItems = 48 * (3 + props.totalItems) + 2;
		//console.log(container.current.clientHeight, window.innerHeight, heightOfAllItems);
		if (container.current.clientHeight < heightOfAllItems) {
			setShowArrows(true);
		} else {
			setShowArrows(false);
		}
	};

	return (
		<aside
			className={`${props.navigationOpen ? '' : 'collapsed'} ${
				props.mobileMenuOpen ? '' : 'closed'
			}`}
			ref={container}
		>
			<div className='aside-inner'>
				<div className='nav-top'>
					<button
						type='button'
						className='gr-btn link-btn'
						id='minimizeBtn'
						onClick={props.toggleNavigation}
					>
						<svg
							focusable='false'
							className='icon-18'
							aria-hidden='true'
						>
							<use
								xmlnsXlink='http://www.w3.org/1999/xlink'
								xlinkHref={`#${
									props.navigationOpen ? 'collapse' : 'expand'
								}-nav`}
							></use>
						</svg>
						<span>Minimize item list</span>
					</button>
					<button
						type='button'
						className='gr-btn gr-icon-btn'
						id='closeBtn'
						onClick={props.toggleMobileMenu}
					>
						<svg
							focusable='false'
							className='icon-18'
							aria-hidden='true'
						>
							<use
								xmlnsXlink='http://www.w3.org/1999/xlink'
								xlinkHref={`#close-18`}
							></use>
						</svg>
					</button>
				</div>
				<button
					className='gr-btn icon-btn-24 scroll-btn'
					onClick={handlePrevBtn}
					disabled={!showArrows}
				>
					<svg
						focusable='false'
						className='icon-24'
						aria-hidden='true'
					>
						<use
							xmlnsXlink='http://www.w3.org/1999/xlink'
							xlinkHref='#descending-24'
						></use>
					</svg>
				</button>
				<nav ref={navContainerRef}>
					<div ref={navRef}>
						{props.data.assignmentParts.map((part, i) => {
							return (
								<ol
									className='assignment-group'
									key={`part${i}`}
								>
									{part.assignmentItems.map((item, j) => {
										itemCount = itemCount + 1;
										return (
											<li key={`item${itemCount}`}>
												<AssignmentCard
													data={item}
													itemCount={itemCount}
													currentItem={props.currentItem === itemCount}
													gotoItem={props.gotoItem}
													ref={ref}
												/>
											</li>
										);
									})}
								</ol>
							);
						})}
					</div>
				</nav>
				<button
					className='gr-btn icon-btn-24 scroll-btn'
					onClick={handleNextBtn}
					disabled={!showArrows}
				>
					<svg
						focusable='false'
						className='icon-24'
						aria-hidden='true'
					>
						<use
							xmlnsXlink='http://www.w3.org/1999/xlink'
							xlinkHref='#ascending-24'
						></use>
					</svg>
				</button>
			</div>
		</aside>
	);
});

export default PLayerNavigation;
