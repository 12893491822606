import footerControls from './assets/images/etext_footer.png';
import data from './assets/data/stats.json';
import { useEffect, useState } from 'react';

const EText = (props) => {
    const [itemIndex] = useState(localStorage.getItem('currentItem'));
    const [readingContent, setReadingContent] = useState(data.assignmentParts[0].assignmentItems[2]);

    useEffect(() => {
       
        if (itemIndex === '1') {
            setReadingContent(data.assignmentParts[0].assignmentItems[1]);
        } 
    }, [readingContent, setReadingContent, itemIndex]);

    return (
        <div className="etext">
            <aside className="etext__navbar">
                <button className="gr-btn icon-btn-24">
                    <svg focusable="false" className="icon-24" aria-hidden="true">
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#home-outline-24"></use>
                    </svg>
                </button>
                <ul>
                    <li key="control1">
                        <button className="gr-btn icon-btn-24">
                            <svg focusable="false" className="icon-24" aria-hidden="true">
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#list-view-24"></use>
                            </svg>
                        </button>
                    </li>
                    <li key="control2">
                        <button className="gr-btn icon-btn-24">
                            <svg focusable="false" className="icon-24" aria-hidden="true">
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#scrubber-24"></use>
                            </svg>
                        </button>
                    </li>
                    <li key="control3">
                        <button className="gr-btn icon-btn-24">
                            <svg focusable="false" className="icon-24" aria-hidden="true">
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#search-24"></use>
                            </svg>
                        </button>
                    </li>
                    <li key="control4">
                        <button className="gr-btn icon-btn-24">
                            <svg focusable="false" className="icon-24" aria-hidden="true">
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#notebook-alt-24"></use>
                            </svg>
                        </button>
                    </li>
                    <li key="control5">
                        <button className="gr-btn icon-btn-24">
                            <svg focusable="false" className="icon-24" aria-hidden="true">
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#line-chart-24"></use>
                            </svg>
                        </button>
                    </li>
                    <li key="control6">
                        <button className="gr-btn icon-btn-24">
                            <svg focusable="false" className="icon-24" aria-hidden="true">
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#font-24"></use>
                            </svg>
                        </button>
                    </li>
                </ul>

                <button className="gr-btn icon-btn-24 btn-close">
                    <svg focusable="false" className="icon-24" aria-hidden="true">
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#close-24"></use>
                    </svg>
                </button>

            </aside>
            <header className="etext__header">
                <h1>Chapter 10. Explain the Logic of Hypothesis Testing</h1>

                <div className="user-id">PM</div>

            </header>
            <main className="etext__body">
                <article className="page">
                    {readingContent.content.map((contentPart, i) => {
                        return(
                            contentPart.content.map((item, j) => {
                                return(
                                    <div className="xln-content" dangerouslySetInnerHTML={{ __html: item.text }} key={`content${j}`} />
                                )
                            })
                        )
                    })}
                </article>
            </main>
            <footer className="etext__footer">
                <img src={footerControls} alt="" />
            </footer>
        </div>

    )
}

export default  EText;