// State held component not using Redux
import React, { useState } from 'react';

const AnswerOptions = (props) => {

    const [checked, setChecked] = useState(false)
console.log(props.questionState.answerValue)
    return (
        <ul className="answer-options" id="answerOptions"  >
            {props.options.map((ansOption, index) => { 
                let checked = props.questionState.answerValue !== "" && Number(props.questionState.answerValue) === index
                console.log(props.questionState.answerValue !== "" && Number(props.questionState.answerValue) === index)
                return(
                props.correctAnswer === index && props.showCorrect ?
                    <li key={`ansoption${index}`} className="correct-ans">
                        <svg focusable="false" className="icon-24 radio-correct-pl" aria-hidden="true">
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#single-correct-24"></use>
                        </svg>
                        <span className="label" dangerouslySetInnerHTML={{__html: ansOption.text}}></span>
                    </li>  
                // : props.selectedAnswers[props.currentPart].ansState === 'wrong' && props.selectedAnswers[props.currentPart].answer === index ?
                //     <li key={`ansoption${index}`} className='incorrect-ans'>
                //         <svg focusable="false" className="icon-24 radio-incorrect-pl" aria-hidden="true">
                //             <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#single-incorrect-24"></use>
                //         </svg>
                //         <span className="label">{ansOption.answer}</span>
                //     </li>
                :
                    <li key={`ansoption${index}${checked ? 'checekd':''}`}
                        className={`${(props.showCorrect && props.correctAnswer === index) ? "correct-ans" : ""} ${(props.showCorrect) ? 'disabled': ''}`}>
                        <div className={`gr-radio ${props.showCorrect && props.correctAnswer === index ? "corrected" : ""} `}>
                            <input type="radio" 
                                name={`${props.random}-ansOption${props.currentPart}`}
                                id={`option${props.currentPart}${index}`} 
                                value={index}
                                onChange={(e) => props.selectAnswer(e)}
                                defaultChecked={props.questionState.answerValue !== "" && Number(props.questionState.answerValue) === index}
                                disabled={props.correctAnswer === index || props.showCorrect}
                            />
                            <label htmlFor={`option${props.currentPart}${index}`} dangerouslySetInnerHTML={{__html: ansOption.text}}></label>
                            <span>
                                <svg focusable="false" className="icon-24 select-icon" aria-hidden="true">
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#single-selected-24"></use>
                                </svg>
                                <svg focusable="false" className="icon-24 correct-icon" aria-hidden="true">
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#single-correct-24"></use>
                                </svg>
                                <svg focusable="false" className="icon-24 incorrect-icon" aria-hidden="true">
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#single-incorrect-24"></use>
                                </svg>
                            </span>
                        </div>
                    </li>
                )
            })}
        </ul>
    );
}

export default AnswerOptions;
