
import OverallScore from "./OverallScore";

const OverviewPageHero = (props) => {

    let completionPercentage = (props.itemsCompleted / props.totalItems) * 100;

    const formatTime = (seconds) => {
        let minutes = Math.floor(seconds / 60);
        let extraSeconds = seconds % 60;
        extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;

        return minutes
    }
    
    return(
        <section className={`overview-hero ${props.inView ? '' : 'pinned'}`}>
            <span className="gradient" aria-hidden="true"></span>
            <div className="wrapper">
                <div className="overview-hero__left">
                    <p className="assignment-type">{props.assighmentType}</p>
                    <h2>{props.assignmentName}</h2>

                    <ul className="meta-data">
                        <li>
                            <span className="label">Due:</span>
                            <span className="item-value">{props.dueDate} at {props.dueTime}</span>
                        </li>
                        
                        <li><a href="!#">See late policy</a></li>
                    </ul>
                    <ul className="meta-data points">
                    <li>
                        <span className="label">Est time:</span>
                        <span className="item-value">{props.totalTime} min</span>
                        </li>
                        <li>
                        <span className="label">Worth:</span>
                        <span className="item-value">{props.totalPoints} pts</span>
                            
                        </li>
                       
                    </ul>
                </div>
                <div className="overview-hero__right">
                    <OverallScore 
                        overallScore={props.overallScore} 
                        totalPoints={props.totalPoints}
                        totalItems={props.totalItems}
                        itemsCompleted={props.itemsCompleted}
                    />                    
                </div>

                <div className="action-bar">
                    {props.itemsCompleted === props.totalItems ?
                        <button className="gr-btn primary">Review</button>
                    : props.itemsCompleted !== 0 ?
                        <button className="gr-btn primary" onClick={props.openPlayer}>Resume</button>
                    :
                        <button className="gr-btn primary" onClick={props.openPlayer}>Get Started</button>
                    }

                    {props.itemsCompleted > 0 &&
                        <div className="progress-indicator">
                            <span className="progress-info">{props.itemsCompleted} of {props.totalItems} completed</span>
                            <div className="progress">
                                <span style={{"width": completionPercentage + '%'}}></span>
                            </div>
                        </div>
                    }
                </div>

            </div>
        </section>
    )
}

export default OverviewPageHero;