/** @format */

import { useEffect, useState } from 'react';
import Header from './Header';
import OverviewPage from './OverviewPage';
import Footer from './Footer';
import Player from './Player';
import ChatbotContainer from './chatbot/ChatbotContainer';
import assignmentDataap from './assets/data/skeletal_muscle.json';
import { useInView } from 'react-intersection-observer';
import { useSelector, useDispatch } from 'react-redux';
import { completeItem, attemptItem, viewedItem } from './redux/userSlice';
import SplitPane, { Pane } from 'react-split-pane';

const MainPage = (props) => {
	const userData = useSelector((state) => state.user);
	//console.log(userData);

	const dispatch = useDispatch();
	const [data, setData] = useState(assignmentDataap);
	const [itemOpen, setItemOpen] = useState(false);
	const [currentItem, setCurrentItem] = useState(0);
	const [currentStep, setCurrentStep] = useState(0);
	const [totalSteps, setTotalSteps] = useState(1);
	const [itemsList, setItemsList] = useState([]);

	/* Chat Bot States ---*/
	const [drawerOpen, setDrawerOpen] = useState(true);
	const [drawerMode, setDrawerMode] = useState('sidebyside'); //overlay, floating, sidebyside
	const [drawerType, setDrawerType] = useState('chat');
	const [chatTipOpen, setChatTipOpen] = useState(!props.drawerOpen);

	const closeChatTip = (e) => {
		e.preventDefault();
		setChatTipOpen(false);
	};

	const changeDrawerType = (foo) => {
		setDrawerType(foo);
	};
	const changeDrawerMode = (mode) => {
		setDrawerMode(mode);
	};
	const changeDrawerOpen = (foo) => {
		setDrawerOpen(foo);
		if (!foo) {
			setChatTipOpen(true);
		}
	};

	const { ref, inView } = useInView({
		/* Optional options */
		threshold: 0,
	});

	const setSelectedTopic = () => {
		let tempList2 = [];
		assignmentDataap.assignmentParts.forEach((element) => {
			element.assignmentItems.forEach((item, i) => {
				tempList2.push(item);
			});
		}, data);

		return tempList2;
	};

	const openPlayer = () => {
		setItemOpen(true);
		//do this better as a global setting so if user closes it, it won't open on its own
		// setDrawerOpen(true);
	};

	const closePlayer = () => {
		setItemOpen(false);
	};

	//Navigate to next item - Called from the next button
	const nextItem = () => {
		/* Check how many steps */
		if (totalSteps > 1 && currentStep < totalSteps - 1) {
			setCurrentStep(currentStep + 1);
		} else {
			/* Set current item as complete */
			let itemPoints = itemsList[currentItem].totalPoints
				? itemsList[currentItem].totalPoints
				: 0;
			let totalScore = data.overallscore + itemPoints;

			var tempList = [...itemsList];
			if (tempList[currentItem].status !== 'completed') {
				tempList[currentItem].pointsEarned = itemPoints;
				tempList[currentItem].status = 'completed';

				/* Update status of items in the full stack */
				let fullData = data;
				fullData.assignmentParts.forEach((element) => {
					element.assignmentItems.forEach((e) => {
						if (e.itemid === itemsList[currentItem].itemId) {
							e.pointsEarned = itemPoints;
							e.status = 'completed';
						}
					});
				});

				/* Update total score and items completed */
				fullData.itemsCompleted = data.itemsCompleted + 1;
				fullData.overallscore = totalScore;

				setData(fullData);
				setItemsList(tempList);

				dispatch(viewedItem(itemsList[currentItem]));

				//tie to attempts for question types
				if (itemsList[currentItem].type !== 'question') {
					dispatch(completeItem(itemsList[currentItem]));
				}

				/* Check if the locked item needs to be unlocked */
				// checkToUnlock(7);
			}

			if (currentItem < tempList.length - 1) {
				/* Check if question has multiple parts ---*/
				if (
					tempList[currentItem + 1].type === 'question' &&
					typeof tempList[currentItem + 1].content[0].answer === 'object'
				) {
					setTotalSteps(tempList[currentItem + 1].content[0].answer.length);
					setCurrentStep(0);
				}

				/* Check if item is still locked ---*/
				if (tempList[currentItem + 1].locked) {
					setCurrentItem(currentItem + 2);
				} else {
					setCurrentItem(currentItem + 1);
				}
			} else {
				setItemOpen(false);
			}

			document.getElementById('assignmentContent').scrollTo(0, 0);
		}
	};
	const prevItem = (num) => {
		gotoItem(num);
	};

	// Checks if Locked item can be unlocked & unlocks it
	const checkToUnlock = (itemIndex) => {
		let tempList = [...itemsList];
		let prereqItems = tempList.filter(
			(a) => a.prereqfor === tempList[itemIndex].itemid
		);
		//console.log(prereqItems);
		let lockedState = false;
		if (tempList[itemIndex].locked) {
			for (var i = 0; i < prereqItems.length; i++) {
				if (prereqItems[i].status !== 'completed') {
					lockedState = true;
				}
			}
			tempList[itemIndex].locked = lockedState;
		}
		let fullData = data;
		fullData.assignmentParts.forEach((element) => {
			element.assignmentItems.forEach((e) => {
				if (e.itemid === itemsList[itemIndex].itemId && lockedState) {
					e.locked = true;
				}
			});
		});

		setData(fullData);
		setItemsList(tempList);
	};

	//Navigate to a specfic item. Called from cards.
	const gotoItem = (itemNum) => {
		// ß;
		if (!itemOpen) {
			setItemOpen(true);
			//do this better as a global setting so if user closes it, it won't open on its own
			setDrawerOpen(true);
		}
		setCurrentItem(itemNum);

		/* Check if the locked item needs to be unlocked */
		// checkToUnlock(7);
	};
	useEffect(() => {
		setItemsList(setSelectedTopic());
	}, [setItemsList]);

	useEffect(() => {
		if (data.itemsCompleted === data.totalItems) {
			//check if they closed the tooltips
			if (chatTipOpen) {
				setDrawerOpen(true);
			}
		}
	}, [data.itemsCompleted, data.totalItems]);

	return (
		<>
			<div className='main-container'>
				{itemOpen ? (
					<Player
						closePlayer={closePlayer}
						data={data}
						currentItem={currentItem}
						nextItem={nextItem}
						prevItem={prevItem}
						itemsList={itemsList}
						gotoItem={gotoItem}
						itemsCompleted={data.itemsCompleted}
						totalItems={data.totalItems}
						currentStep={currentStep}
						totalSteps={totalSteps}
						drawerOpen={drawerOpen}
					/>
				) : (
					<>
						<Header
							itemOpen={itemOpen}
							ref={ref}
						/>
						<OverviewPage
							data={data}
							setData={setData}
							openPlayer={openPlayer}
							gotoItem={gotoItem}
							inView={inView}
						/>
						<Footer />
					</>
				)}
			</div>
			<ChatbotContainer
				pageType={itemOpen ? 'assignment' : 'overview'}
				item={itemOpen ? itemsList[currentItem] : null}
				currentItem={currentItem}
				drawerOpen={drawerOpen}
				drawerMode={drawerMode}
				drawerType={drawerType}
				changeDrawerType={changeDrawerType}
				changeDrawerMode={changeDrawerMode}
				changeDrawerOpen={changeDrawerOpen}
				assignmentComplete={data.itemsCompleted === data.totalItems}
				closeChatTip={closeChatTip}
				chatTipOpen={chatTipOpen}
				gotoItem={gotoItem}
			/>
		</>
	);
};

export default MainPage;
