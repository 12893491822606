import { ArrowRight, ChevronLeft, ChevronRight, East } from "@mui/icons-material";
import { useState } from "react"


const PlayerFooter = (props) => {
    const [actionDDOpen, setActionDDOpen] = useState(false);

    const toggleActionDD = () => {
        setActionDDOpen(!actionDDOpen);
    }

    return(
        <footer className={`player-footer ${props.navigationOpen ? '' : 'full-width'}`}>
            <div className="player-footer__left">
                <button className="gr-btn icon-btn-24 previous" aria-label="Previous" onClick={props.prevItem}>
                    {/* <span>Next</span>
                    <East/> */}
                    <ChevronLeft></ChevronLeft>
                    
                </button>
            </div>
            <div className="player-footer__right">
                {/* {props.itemType === 'reading' &&
                    <a href="#/etext" className="gr-btn icon-btn-24" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M12.9545 5.25C12.9545 4.83579 12.6188 4.5 12.2045 4.5H2.25C1.00736 4.5 0 5.50736 0 6.75V21.75C0 22.9926 1.00736 24 2.25 24H17.25C18.4926 24 19.5 22.9926 19.5 21.75V11.7955C19.5 11.3812 19.1642 11.0455 18.75 11.0455C18.3358 11.0455 18 11.3812 18 11.7955V21.75C18 22.1642 17.6642 22.5 17.25 22.5H2.25C1.83579 22.5 1.5 22.1642 1.5 21.75V6.75C1.5 6.33579 1.83579 6 2.25 6H12.2045C12.6188 6 12.9545 5.66421 12.9545 5.25Z" fill="#4F5561"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M24 0.75C24 0.335786 23.6642 0 23.25 0H15.75C15.3358 0 15 0.335786 15 0.75C15 1.16421 15.3358 1.5 15.75 1.5H21.4393L9.21967 13.7197C8.92678 14.0126 8.92678 14.4874 9.21967 14.7803C9.51256 15.0732 9.98744 15.0732 10.2803 14.7803L22.5 2.56066V8.25C22.5 8.66421 22.8358 9 23.25 9C23.6642 9 24 8.66421 24 8.25V0.75Z" fill="#4F5561"/>
                        </svg>
                    </a>
                } */}
               
                <button className="gr-btn icon-btn-24 primary next" aria-label="Next" onClick={props.nextItem}>
                    <ChevronRight></ChevronRight>
                    {/* <span>Next</span>
                    <East/> */}
                    {/* <ArrowRight/> */}
                    </button>
            </div>
        </footer>
    )
}

export default PlayerFooter