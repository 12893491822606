import React, { useEffect, useRef, useState } from "react";

const ChatMCQ = (props) => {
    const [selected, setSelected] = useState(undefined);
    const feedback = useRef();
    let random = Math.random().toFixed(4);

    useEffect(() => {
        if (feedback.current) {
            feedback.current.scrollIntoView({ behavior: 'smooth' });
        }
    },[selected])
    return (

        <div className="chat-mcq">
            <fieldset className="gr-fieldset">
                <legend>{props.data.legend}</legend>
                <div className="radio-options">
                    {props.data.options.map((option,index) => {
                        return (
                            <div className="option">
                                <div className={selected === index && option.correct ? "gr-radio correct" : selected === index ? "gr-radio incorrect" : "gr-radio"}>
                                    <input type="radio" name={"mcq"+random} id={"option"+random+"-"+index} onChange={()=> setSelected(index)}/>
                                    <label htmlFor={"option"+random+"-"+index}>{option.text}</label>
                                    <span>
                                        {selected === index && option.correct ? 
                                            <svg focusable="false" className="icon-24 correct-icon" aria-hidden="true">
                                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#single-correct-24"></use>
                                            </svg>
                                        : selected === index ? 
                                            <svg focusable="false" className="icon-24 incorrect-icon" aria-hidden="true">
                                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#single-incorrect-24"></use>
                                            </svg>
                                        : 
                                        <svg focusable="false" className="icon-24 select-icon" aria-hidden="true">
                                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#single-selected-24"></use>
                                        </svg>
                                        }
                                        
                                    </span>
                                </div>
                                
                            </div>
                        )
                    })}
                    

                </div>
                {selected !== undefined && 
                <div className="feedback option-feedback" ref={feedback}>
                    <p dangerouslySetInnerHTML={{__html: props.data.options[selected].feedback}}/>
                    </div> }
            </fieldset>

        </div>
    )

}

export default ChatMCQ