
import PlayerFooter from "./PlayerFooter";
import Accordion from './Accordion';
import TDXBody from "./tdx/TDXBody";
import SplitPane from 'react-split-pane';
import './assets/css/bronte_theme2.css';
import { forwardRef } from "react";

const PlayerContent = forwardRef((props, ref) => {
    
    return(
        <article className="assignment-content">
            <section className="item-content" id="assignmentContent">
                {props.data.content.map((contentPart, i) => {
                    return(
                        <>
                            {contentPart.type === 'video' ?               
                                <div className="content-part" key={`content${i}`}>      
                                    <h3>{contentPart.name}</h3>           
                                    <iframe src={contentPart.sourceLink} className="video-player" title={contentPart.name} style={{minHeight:"480px"}}></iframe>
                                    {/* <video controls aria-label="Video Player" preload="metadata" id="video" poster={contentPart.poster} className="video-player">	
                                        <source src={contentPart.video} type="video/mp4" />
                                        <p>
                                            Your browser doesn't support HTML5 video.
                                        </p>
                                    </video>              */}
                                </div>               
                            : contentPart.type === 'applet' ?
                                <div className="content-part full-width" key={`content${i}`}>
                                    <iframe src={contentPart.link} title="StatCrunch Applet"></iframe>
                                </div>
                            : contentPart.type === 'narrative' ?
                                <div className="content-part" key={`content${i}`}>
                                    <h3>{contentPart.name}</h3>           
                                    {contentPart.content.map((item, j) => {
                                        return(
                                            <p key={`para${j}`}>{item.text}</p>
                                        )
                                    })}
                                </div>
                            : contentPart.type === 'accordion' ?
                                <div className="content-part" key={`content${i}`}>
                                    {contentPart.content.map((item, j) => {
                                        return(
                                            <Accordion data={item} key={`accordion${j}`} />
                                        )
                                    })}
                                </div>
                            : contentPart.type === 'question' ?  
                                    <div className="content-part" key={`content${i}`}>
                                        <TDXBody data={props.data} currentQuestion={props.currentItem} selectedAnswers={props.selectedAnswer} selectAsnwer={() => props.selectAsnwer(j)} currentPart={1} />
                                       
                                    </div>
                            : contentPart.type === 'reading' ?
                                <div className="content-part bronte-styles" key={`content${i}`}>
                                    {/* <h3>{contentPart.name}</h3>            */}
                                    {contentPart.content && <div className="xln-content" dangerouslySetInnerHTML={{ __html: contentPart.content }} />}
                                </div>
                            : null}
                        </>
                    ) 
                })}
                    
            </section>

            <PlayerFooter 
                currentItem={props.currentItem} 
                action={props.data.action} 
                nextItem={props.nextItem}
                prevItem={props.prevItem}
                answerSelected={props.answerSelected}
                checkAnswer={props.checkAnswer}
                answerCorrect={props.answerCorrect}
                itemType={props.data.type}
                navigationOpen={props.navigationOpen}
                subcat={props.data.subcat}
            />
        </article>
    )
})

export default PlayerContent