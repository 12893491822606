import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from "react";

const ChatHistory = () => {

    return (
        <section className='chat-history'>
			<form>
				<input type='search' placeholder="Filter by chat title"/>
			</form>
            <div className='chat-history-results'>
                <div className="chat-history-group">
                    <h2>Today</h2>
                    <ol className="chat-history-result-list">
                        <li>
                            <button className="chat-history-result-item">
                                <span className='icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 1.99991C14.4183 1.99991 18 5.58163 18 9.99991C18 14.4182 14.4183 17.9999 10 17.9999C8.72679 17.9999 7.49591 17.7018 6.38669 17.1392L6.266 17.0749L2.62109 17.985C2.31127 18.0624 2.02622 17.8368 2.00131 17.5437L2.00114 17.4623L2.01493 17.3786L2.925 13.7349L2.86169 13.6152C2.4066 12.7185 2.12433 11.7421 2.03275 10.7282L2.00738 10.3462L2 9.99991C2 5.58163 5.58172 1.99991 10 1.99991ZM10 2.99991C6.13401 2.99991 3 6.13391 3 9.99991C3 11.2169 3.31054 12.3877 3.89352 13.4248C3.94046 13.5083 3.9621 13.6029 3.95692 13.6972L3.94274 13.7911L3.187 16.8119L6.21104 16.0582C6.27294 16.0428 6.33662 16.0395 6.39873 16.0478L6.4903 16.069L6.57701 16.1074C7.61362 16.6897 8.7837 16.9999 10 16.9999C13.866 16.9999 17 13.8659 17 9.99991C17 6.13391 13.866 2.99991 10 2.99991ZM10.5 10.9999C10.7761 10.9999 11 11.2238 11 11.4999C11 11.7454 10.8231 11.9495 10.5899 11.9919L10.5 11.9999H7.5C7.22386 11.9999 7 11.7761 7 11.4999C7 11.2544 7.17687 11.0503 7.41012 11.008L7.5 10.9999H10.5ZM12.5 7.99991C12.7761 7.99991 13 8.22377 13 8.49991C13 8.74537 12.8231 8.94952 12.5899 8.99185L12.5 8.99991H7.5C7.22386 8.99991 7 8.77605 7 8.49991C7 8.25445 7.17687 8.0503 7.41012 8.00796L7.5 7.99991H12.5Z" fill="#8A97AD"/>
                                    </svg>
                                </span>
                                <span className='title'>
                                    Skeletal Muscle Fibers
                                </span>
                            </button>
                            <button className="gr-btn icon-btn-18 icon-btn" aria-label="Options">
                                <MoreVertIcon/>
                            </button>
                        </li>
                    </ol>
                </div>
                <div className="chat-history-group">
                    <h2>This week</h2>
                    <ol className="chat-history-result-list">
                        <li>
                            <button className="chat-history-result-item">
                                <span className='icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 1.99991C14.4183 1.99991 18 5.58163 18 9.99991C18 14.4182 14.4183 17.9999 10 17.9999C8.72679 17.9999 7.49591 17.7018 6.38669 17.1392L6.266 17.0749L2.62109 17.985C2.31127 18.0624 2.02622 17.8368 2.00131 17.5437L2.00114 17.4623L2.01493 17.3786L2.925 13.7349L2.86169 13.6152C2.4066 12.7185 2.12433 11.7421 2.03275 10.7282L2.00738 10.3462L2 9.99991C2 5.58163 5.58172 1.99991 10 1.99991ZM10 2.99991C6.13401 2.99991 3 6.13391 3 9.99991C3 11.2169 3.31054 12.3877 3.89352 13.4248C3.94046 13.5083 3.9621 13.6029 3.95692 13.6972L3.94274 13.7911L3.187 16.8119L6.21104 16.0582C6.27294 16.0428 6.33662 16.0395 6.39873 16.0478L6.4903 16.069L6.57701 16.1074C7.61362 16.6897 8.7837 16.9999 10 16.9999C13.866 16.9999 17 13.8659 17 9.99991C17 6.13391 13.866 2.99991 10 2.99991ZM10.5 10.9999C10.7761 10.9999 11 11.2238 11 11.4999C11 11.7454 10.8231 11.9495 10.5899 11.9919L10.5 11.9999H7.5C7.22386 11.9999 7 11.7761 7 11.4999C7 11.2544 7.17687 11.0503 7.41012 11.008L7.5 10.9999H10.5ZM12.5 7.99991C12.7761 7.99991 13 8.22377 13 8.49991C13 8.74537 12.8231 8.94952 12.5899 8.99185L12.5 8.99991H7.5C7.22386 8.99991 7 8.77605 7 8.49991C7 8.25445 7.17687 8.0503 7.41012 8.00796L7.5 7.99991H12.5Z" fill="#8A97AD"/>
                                    </svg>
                                </span>
                                <span className='title'>
                                    Muscle Tissue
                                </span>
                            </button>
                            <button className="gr-btn icon-btn-18 icon-btn" aria-label="Options">
                                <MoreVertIcon/>
                            </button>
                        </li>
                    </ol>
                </div>
                <div className="chat-history-group">
                    <h2>Last week</h2>
                    <ol className="chat-history-result-list">
                    <li>
                            <button className="chat-history-result-item">
                                <span className='icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 1.99991C14.4183 1.99991 18 5.58163 18 9.99991C18 14.4182 14.4183 17.9999 10 17.9999C8.72679 17.9999 7.49591 17.7018 6.38669 17.1392L6.266 17.0749L2.62109 17.985C2.31127 18.0624 2.02622 17.8368 2.00131 17.5437L2.00114 17.4623L2.01493 17.3786L2.925 13.7349L2.86169 13.6152C2.4066 12.7185 2.12433 11.7421 2.03275 10.7282L2.00738 10.3462L2 9.99991C2 5.58163 5.58172 1.99991 10 1.99991ZM10 2.99991C6.13401 2.99991 3 6.13391 3 9.99991C3 11.2169 3.31054 12.3877 3.89352 13.4248C3.94046 13.5083 3.9621 13.6029 3.95692 13.6972L3.94274 13.7911L3.187 16.8119L6.21104 16.0582C6.27294 16.0428 6.33662 16.0395 6.39873 16.0478L6.4903 16.069L6.57701 16.1074C7.61362 16.6897 8.7837 16.9999 10 16.9999C13.866 16.9999 17 13.8659 17 9.99991C17 6.13391 13.866 2.99991 10 2.99991ZM10.5 10.9999C10.7761 10.9999 11 11.2238 11 11.4999C11 11.7454 10.8231 11.9495 10.5899 11.9919L10.5 11.9999H7.5C7.22386 11.9999 7 11.7761 7 11.4999C7 11.2544 7.17687 11.0503 7.41012 11.008L7.5 10.9999H10.5ZM12.5 7.99991C12.7761 7.99991 13 8.22377 13 8.49991C13 8.74537 12.8231 8.94952 12.5899 8.99185L12.5 8.99991H7.5C7.22386 8.99991 7 8.77605 7 8.49991C7 8.25445 7.17687 8.0503 7.41012 8.00796L7.5 7.99991H12.5Z" fill="#8A97AD"/>
                                    </svg>
                                </span>
                                <span className='title'>
                                    Types of joints
                                </span>
                            </button>
                            <button className="gr-btn icon-btn-18 icon-btn" aria-label="Options">
                                <MoreVertIcon/>
                            </button>
                        </li>
                        <li>
                            <button className="chat-history-result-item">
                                <span className='icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 1.99991C14.4183 1.99991 18 5.58163 18 9.99991C18 14.4182 14.4183 17.9999 10 17.9999C8.72679 17.9999 7.49591 17.7018 6.38669 17.1392L6.266 17.0749L2.62109 17.985C2.31127 18.0624 2.02622 17.8368 2.00131 17.5437L2.00114 17.4623L2.01493 17.3786L2.925 13.7349L2.86169 13.6152C2.4066 12.7185 2.12433 11.7421 2.03275 10.7282L2.00738 10.3462L2 9.99991C2 5.58163 5.58172 1.99991 10 1.99991ZM10 2.99991C6.13401 2.99991 3 6.13391 3 9.99991C3 11.2169 3.31054 12.3877 3.89352 13.4248C3.94046 13.5083 3.9621 13.6029 3.95692 13.6972L3.94274 13.7911L3.187 16.8119L6.21104 16.0582C6.27294 16.0428 6.33662 16.0395 6.39873 16.0478L6.4903 16.069L6.57701 16.1074C7.61362 16.6897 8.7837 16.9999 10 16.9999C13.866 16.9999 17 13.8659 17 9.99991C17 6.13391 13.866 2.99991 10 2.99991ZM10.5 10.9999C10.7761 10.9999 11 11.2238 11 11.4999C11 11.7454 10.8231 11.9495 10.5899 11.9919L10.5 11.9999H7.5C7.22386 11.9999 7 11.7761 7 11.4999C7 11.2544 7.17687 11.0503 7.41012 11.008L7.5 10.9999H10.5ZM12.5 7.99991C12.7761 7.99991 13 8.22377 13 8.49991C13 8.74537 12.8231 8.94952 12.5899 8.99185L12.5 8.99991H7.5C7.22386 8.99991 7 8.77605 7 8.49991C7 8.25445 7.17687 8.0503 7.41012 8.00796L7.5 7.99991H12.5Z" fill="#8A97AD"/>
                                    </svg>
                                </span>
                                <span className='title'>
                                    The pelvic girdle and the lower limb
                                </span>
                            </button>
                            <button className="gr-btn icon-btn-18 icon-btn" aria-label="Options">
                                <MoreVertIcon/>
                            </button>
                        </li>
                        <li>
                            <button className="chat-history-result-item">
                                <span className='icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 1.99991C14.4183 1.99991 18 5.58163 18 9.99991C18 14.4182 14.4183 17.9999 10 17.9999C8.72679 17.9999 7.49591 17.7018 6.38669 17.1392L6.266 17.0749L2.62109 17.985C2.31127 18.0624 2.02622 17.8368 2.00131 17.5437L2.00114 17.4623L2.01493 17.3786L2.925 13.7349L2.86169 13.6152C2.4066 12.7185 2.12433 11.7421 2.03275 10.7282L2.00738 10.3462L2 9.99991C2 5.58163 5.58172 1.99991 10 1.99991ZM10 2.99991C6.13401 2.99991 3 6.13391 3 9.99991C3 11.2169 3.31054 12.3877 3.89352 13.4248C3.94046 13.5083 3.9621 13.6029 3.95692 13.6972L3.94274 13.7911L3.187 16.8119L6.21104 16.0582C6.27294 16.0428 6.33662 16.0395 6.39873 16.0478L6.4903 16.069L6.57701 16.1074C7.61362 16.6897 8.7837 16.9999 10 16.9999C13.866 16.9999 17 13.8659 17 9.99991C17 6.13391 13.866 2.99991 10 2.99991ZM10.5 10.9999C10.7761 10.9999 11 11.2238 11 11.4999C11 11.7454 10.8231 11.9495 10.5899 11.9919L10.5 11.9999H7.5C7.22386 11.9999 7 11.7761 7 11.4999C7 11.2544 7.17687 11.0503 7.41012 11.008L7.5 10.9999H10.5ZM12.5 7.99991C12.7761 7.99991 13 8.22377 13 8.49991C13 8.74537 12.8231 8.94952 12.5899 8.99185L12.5 8.99991H7.5C7.22386 8.99991 7 8.77605 7 8.49991C7 8.25445 7.17687 8.0503 7.41012 8.00796L7.5 7.99991H12.5Z" fill="#8A97AD"/>
                                    </svg>
                                </span>
                                <span className='title'>
                                    The pectoral girdle and the upper limb
                                </span>
                            </button>
                            <button className="gr-btn icon-btn-18 icon-btn" aria-label="Options">
                                <MoreVertIcon/>
                            </button>
                        </li>
                        <li>
                            <button className="chat-history-result-item">
                                <span className='icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 1.99991C14.4183 1.99991 18 5.58163 18 9.99991C18 14.4182 14.4183 17.9999 10 17.9999C8.72679 17.9999 7.49591 17.7018 6.38669 17.1392L6.266 17.0749L2.62109 17.985C2.31127 18.0624 2.02622 17.8368 2.00131 17.5437L2.00114 17.4623L2.01493 17.3786L2.925 13.7349L2.86169 13.6152C2.4066 12.7185 2.12433 11.7421 2.03275 10.7282L2.00738 10.3462L2 9.99991C2 5.58163 5.58172 1.99991 10 1.99991ZM10 2.99991C6.13401 2.99991 3 6.13391 3 9.99991C3 11.2169 3.31054 12.3877 3.89352 13.4248C3.94046 13.5083 3.9621 13.6029 3.95692 13.6972L3.94274 13.7911L3.187 16.8119L6.21104 16.0582C6.27294 16.0428 6.33662 16.0395 6.39873 16.0478L6.4903 16.069L6.57701 16.1074C7.61362 16.6897 8.7837 16.9999 10 16.9999C13.866 16.9999 17 13.8659 17 9.99991C17 6.13391 13.866 2.99991 10 2.99991ZM10.5 10.9999C10.7761 10.9999 11 11.2238 11 11.4999C11 11.7454 10.8231 11.9495 10.5899 11.9919L10.5 11.9999H7.5C7.22386 11.9999 7 11.7761 7 11.4999C7 11.2544 7.17687 11.0503 7.41012 11.008L7.5 10.9999H10.5ZM12.5 7.99991C12.7761 7.99991 13 8.22377 13 8.49991C13 8.74537 12.8231 8.94952 12.5899 8.99185L12.5 8.99991H7.5C7.22386 8.99991 7 8.77605 7 8.49991C7 8.25445 7.17687 8.0503 7.41012 8.00796L7.5 7.99991H12.5Z" fill="#8A97AD"/>
                                    </svg>
                                </span>
                                <span className='title'>
                                    The thoracic cage
                                </span>
                            </button>
                            <button className="gr-btn icon-btn-18 icon-btn" aria-label="Options">
                                <MoreVertIcon/>
                            </button>
                        </li>
                        <li>
                            <button className="chat-history-result-item">
                                <span className='icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 1.99991C14.4183 1.99991 18 5.58163 18 9.99991C18 14.4182 14.4183 17.9999 10 17.9999C8.72679 17.9999 7.49591 17.7018 6.38669 17.1392L6.266 17.0749L2.62109 17.985C2.31127 18.0624 2.02622 17.8368 2.00131 17.5437L2.00114 17.4623L2.01493 17.3786L2.925 13.7349L2.86169 13.6152C2.4066 12.7185 2.12433 11.7421 2.03275 10.7282L2.00738 10.3462L2 9.99991C2 5.58163 5.58172 1.99991 10 1.99991ZM10 2.99991C6.13401 2.99991 3 6.13391 3 9.99991C3 11.2169 3.31054 12.3877 3.89352 13.4248C3.94046 13.5083 3.9621 13.6029 3.95692 13.6972L3.94274 13.7911L3.187 16.8119L6.21104 16.0582C6.27294 16.0428 6.33662 16.0395 6.39873 16.0478L6.4903 16.069L6.57701 16.1074C7.61362 16.6897 8.7837 16.9999 10 16.9999C13.866 16.9999 17 13.8659 17 9.99991C17 6.13391 13.866 2.99991 10 2.99991ZM10.5 10.9999C10.7761 10.9999 11 11.2238 11 11.4999C11 11.7454 10.8231 11.9495 10.5899 11.9919L10.5 11.9999H7.5C7.22386 11.9999 7 11.7761 7 11.4999C7 11.2544 7.17687 11.0503 7.41012 11.008L7.5 10.9999H10.5ZM12.5 7.99991C12.7761 7.99991 13 8.22377 13 8.49991C13 8.74537 12.8231 8.94952 12.5899 8.99185L12.5 8.99991H7.5C7.22386 8.99991 7 8.77605 7 8.49991C7 8.25445 7.17687 8.0503 7.41012 8.00796L7.5 7.99991H12.5Z" fill="#8A97AD"/>
                                    </svg>
                                </span>
                                <span className='title'>
                                    The vertebral column
                                </span>
                            </button>
                            <button className="gr-btn icon-btn-18 icon-btn" aria-label="Options">
                                <MoreVertIcon/>
                            </button>
                        </li>
                        <li>
                            <button className="chat-history-result-item">
                                <span className='icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 1.99991C14.4183 1.99991 18 5.58163 18 9.99991C18 14.4182 14.4183 17.9999 10 17.9999C8.72679 17.9999 7.49591 17.7018 6.38669 17.1392L6.266 17.0749L2.62109 17.985C2.31127 18.0624 2.02622 17.8368 2.00131 17.5437L2.00114 17.4623L2.01493 17.3786L2.925 13.7349L2.86169 13.6152C2.4066 12.7185 2.12433 11.7421 2.03275 10.7282L2.00738 10.3462L2 9.99991C2 5.58163 5.58172 1.99991 10 1.99991ZM10 2.99991C6.13401 2.99991 3 6.13391 3 9.99991C3 11.2169 3.31054 12.3877 3.89352 13.4248C3.94046 13.5083 3.9621 13.6029 3.95692 13.6972L3.94274 13.7911L3.187 16.8119L6.21104 16.0582C6.27294 16.0428 6.33662 16.0395 6.39873 16.0478L6.4903 16.069L6.57701 16.1074C7.61362 16.6897 8.7837 16.9999 10 16.9999C13.866 16.9999 17 13.8659 17 9.99991C17 6.13391 13.866 2.99991 10 2.99991ZM10.5 10.9999C10.7761 10.9999 11 11.2238 11 11.4999C11 11.7454 10.8231 11.9495 10.5899 11.9919L10.5 11.9999H7.5C7.22386 11.9999 7 11.7761 7 11.4999C7 11.2544 7.17687 11.0503 7.41012 11.008L7.5 10.9999H10.5ZM12.5 7.99991C12.7761 7.99991 13 8.22377 13 8.49991C13 8.74537 12.8231 8.94952 12.5899 8.99185L12.5 8.99991H7.5C7.22386 8.99991 7 8.77605 7 8.49991C7 8.25445 7.17687 8.0503 7.41012 8.00796L7.5 7.99991H12.5Z" fill="#8A97AD"/>
                                    </svg>
                                </span>
                                <span className='title'>
                                    The skull
                                </span>
                            </button>
                            <button className="gr-btn icon-btn-18 icon-btn" aria-label="Options">
                                <MoreVertIcon/>
                            </button>
                        </li>
                    </ol>
                </div>
                <div className="chat-history-group">
                    <h2>2 weeks ago</h2>
                    <ol className="chat-history-result-list">
                        <li>
                            <button className="chat-history-result-item">
                                <span className='icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 1.99991C14.4183 1.99991 18 5.58163 18 9.99991C18 14.4182 14.4183 17.9999 10 17.9999C8.72679 17.9999 7.49591 17.7018 6.38669 17.1392L6.266 17.0749L2.62109 17.985C2.31127 18.0624 2.02622 17.8368 2.00131 17.5437L2.00114 17.4623L2.01493 17.3786L2.925 13.7349L2.86169 13.6152C2.4066 12.7185 2.12433 11.7421 2.03275 10.7282L2.00738 10.3462L2 9.99991C2 5.58163 5.58172 1.99991 10 1.99991ZM10 2.99991C6.13401 2.99991 3 6.13391 3 9.99991C3 11.2169 3.31054 12.3877 3.89352 13.4248C3.94046 13.5083 3.9621 13.6029 3.95692 13.6972L3.94274 13.7911L3.187 16.8119L6.21104 16.0582C6.27294 16.0428 6.33662 16.0395 6.39873 16.0478L6.4903 16.069L6.57701 16.1074C7.61362 16.6897 8.7837 16.9999 10 16.9999C13.866 16.9999 17 13.8659 17 9.99991C17 6.13391 13.866 2.99991 10 2.99991ZM10.5 10.9999C10.7761 10.9999 11 11.2238 11 11.4999C11 11.7454 10.8231 11.9495 10.5899 11.9919L10.5 11.9999H7.5C7.22386 11.9999 7 11.7761 7 11.4999C7 11.2544 7.17687 11.0503 7.41012 11.008L7.5 10.9999H10.5ZM12.5 7.99991C12.7761 7.99991 13 8.22377 13 8.49991C13 8.74537 12.8231 8.94952 12.5899 8.99185L12.5 8.99991H7.5C7.22386 8.99991 7 8.77605 7 8.49991C7 8.25445 7.17687 8.0503 7.41012 8.00796L7.5 7.99991H12.5Z" fill="#8A97AD"/>
                                    </svg>
                                </span>
                                <span className='title'>
                                    Skin cancer and burns are major challenges to the body
                                </span>
                            </button>
                            <button className="gr-btn icon-btn-18 icon-btn" aria-label="Options">
                                <MoreVertIcon/>
                            </button>
                        </li>
                        <li>
                            <button className="chat-history-result-item">
                                <span className='icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 1.99991C14.4183 1.99991 18 5.58163 18 9.99991C18 14.4182 14.4183 17.9999 10 17.9999C8.72679 17.9999 7.49591 17.7018 6.38669 17.1392L6.266 17.0749L2.62109 17.985C2.31127 18.0624 2.02622 17.8368 2.00131 17.5437L2.00114 17.4623L2.01493 17.3786L2.925 13.7349L2.86169 13.6152C2.4066 12.7185 2.12433 11.7421 2.03275 10.7282L2.00738 10.3462L2 9.99991C2 5.58163 5.58172 1.99991 10 1.99991ZM10 2.99991C6.13401 2.99991 3 6.13391 3 9.99991C3 11.2169 3.31054 12.3877 3.89352 13.4248C3.94046 13.5083 3.9621 13.6029 3.95692 13.6972L3.94274 13.7911L3.187 16.8119L6.21104 16.0582C6.27294 16.0428 6.33662 16.0395 6.39873 16.0478L6.4903 16.069L6.57701 16.1074C7.61362 16.6897 8.7837 16.9999 10 16.9999C13.866 16.9999 17 13.8659 17 9.99991C17 6.13391 13.866 2.99991 10 2.99991ZM10.5 10.9999C10.7761 10.9999 11 11.2238 11 11.4999C11 11.7454 10.8231 11.9495 10.5899 11.9919L10.5 11.9999H7.5C7.22386 11.9999 7 11.7761 7 11.4999C7 11.2544 7.17687 11.0503 7.41012 11.008L7.5 10.9999H10.5ZM12.5 7.99991C12.7761 7.99991 13 8.22377 13 8.49991C13 8.74537 12.8231 8.94952 12.5899 8.99185L12.5 8.99991H7.5C7.22386 8.99991 7 8.77605 7 8.49991C7 8.25445 7.17687 8.0503 7.41012 8.00796L7.5 7.99991H12.5Z" fill="#8A97AD"/>
                                    </svg>
                                </span>
                                <span className='title'>
                                    The skin is a barrier
                                </span>
                            </button>
                            <button className="gr-btn icon-btn-18 icon-btn" aria-label="Options">
                                <MoreVertIcon/>
                            </button>
                        </li>
                        <li>
                            <button className="chat-history-result-item">
                                <span className='icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 1.99991C14.4183 1.99991 18 5.58163 18 9.99991C18 14.4182 14.4183 17.9999 10 17.9999C8.72679 17.9999 7.49591 17.7018 6.38669 17.1392L6.266 17.0749L2.62109 17.985C2.31127 18.0624 2.02622 17.8368 2.00131 17.5437L2.00114 17.4623L2.01493 17.3786L2.925 13.7349L2.86169 13.6152C2.4066 12.7185 2.12433 11.7421 2.03275 10.7282L2.00738 10.3462L2 9.99991C2 5.58163 5.58172 1.99991 10 1.99991ZM10 2.99991C6.13401 2.99991 3 6.13391 3 9.99991C3 11.2169 3.31054 12.3877 3.89352 13.4248C3.94046 13.5083 3.9621 13.6029 3.95692 13.6972L3.94274 13.7911L3.187 16.8119L6.21104 16.0582C6.27294 16.0428 6.33662 16.0395 6.39873 16.0478L6.4903 16.069L6.57701 16.1074C7.61362 16.6897 8.7837 16.9999 10 16.9999C13.866 16.9999 17 13.8659 17 9.99991C17 6.13391 13.866 2.99991 10 2.99991ZM10.5 10.9999C10.7761 10.9999 11 11.2238 11 11.4999C11 11.7454 10.8231 11.9495 10.5899 11.9919L10.5 11.9999H7.5C7.22386 11.9999 7 11.7761 7 11.4999C7 11.2544 7.17687 11.0503 7.41012 11.008L7.5 10.9999H10.5ZM12.5 7.99991C12.7761 7.99991 13 8.22377 13 8.49991C13 8.74537 12.8231 8.94952 12.5899 8.99185L12.5 8.99991H7.5C7.22386 8.99991 7 8.77605 7 8.49991C7 8.25445 7.17687 8.0503 7.41012 8.00796L7.5 7.99991H12.5Z" fill="#8A97AD"/>
                                    </svg>
                                </span>
                                <span className='title'>
                                    Sweat glands versus sebaceous glands
                                </span>
                            </button>
                            <button className="gr-btn icon-btn-18 icon-btn" aria-label="Options">
                                <MoreVertIcon/>
                            </button>
                        </li>
                        <li>
                            <button className="chat-history-result-item">
                                <span className='icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 1.99991C14.4183 1.99991 18 5.58163 18 9.99991C18 14.4182 14.4183 17.9999 10 17.9999C8.72679 17.9999 7.49591 17.7018 6.38669 17.1392L6.266 17.0749L2.62109 17.985C2.31127 18.0624 2.02622 17.8368 2.00131 17.5437L2.00114 17.4623L2.01493 17.3786L2.925 13.7349L2.86169 13.6152C2.4066 12.7185 2.12433 11.7421 2.03275 10.7282L2.00738 10.3462L2 9.99991C2 5.58163 5.58172 1.99991 10 1.99991ZM10 2.99991C6.13401 2.99991 3 6.13391 3 9.99991C3 11.2169 3.31054 12.3877 3.89352 13.4248C3.94046 13.5083 3.9621 13.6029 3.95692 13.6972L3.94274 13.7911L3.187 16.8119L6.21104 16.0582C6.27294 16.0428 6.33662 16.0395 6.39873 16.0478L6.4903 16.069L6.57701 16.1074C7.61362 16.6897 8.7837 16.9999 10 16.9999C13.866 16.9999 17 13.8659 17 9.99991C17 6.13391 13.866 2.99991 10 2.99991ZM10.5 10.9999C10.7761 10.9999 11 11.2238 11 11.4999C11 11.7454 10.8231 11.9495 10.5899 11.9919L10.5 11.9999H7.5C7.22386 11.9999 7 11.7761 7 11.4999C7 11.2544 7.17687 11.0503 7.41012 11.008L7.5 10.9999H10.5ZM12.5 7.99991C12.7761 7.99991 13 8.22377 13 8.49991C13 8.74537 12.8231 8.94952 12.5899 8.99185L12.5 8.99991H7.5C7.22386 8.99991 7 8.77605 7 8.49991C7 8.25445 7.17687 8.0503 7.41012 8.00796L7.5 7.99991H12.5Z" fill="#8A97AD"/>
                                    </svg>
                                </span>
                                <span className='title'>
                                    Are nails scales?
                                </span>
                            </button>
                            <button className="gr-btn icon-btn-18 icon-btn" aria-label="Options">
                                <MoreVertIcon/>
                            </button>
                        </li>
                        <li>
                            <button className="chat-history-result-item">
                                <span className='icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 1.99991C14.4183 1.99991 18 5.58163 18 9.99991C18 14.4182 14.4183 17.9999 10 17.9999C8.72679 17.9999 7.49591 17.7018 6.38669 17.1392L6.266 17.0749L2.62109 17.985C2.31127 18.0624 2.02622 17.8368 2.00131 17.5437L2.00114 17.4623L2.01493 17.3786L2.925 13.7349L2.86169 13.6152C2.4066 12.7185 2.12433 11.7421 2.03275 10.7282L2.00738 10.3462L2 9.99991C2 5.58163 5.58172 1.99991 10 1.99991ZM10 2.99991C6.13401 2.99991 3 6.13391 3 9.99991C3 11.2169 3.31054 12.3877 3.89352 13.4248C3.94046 13.5083 3.9621 13.6029 3.95692 13.6972L3.94274 13.7911L3.187 16.8119L6.21104 16.0582C6.27294 16.0428 6.33662 16.0395 6.39873 16.0478L6.4903 16.069L6.57701 16.1074C7.61362 16.6897 8.7837 16.9999 10 16.9999C13.866 16.9999 17 13.8659 17 9.99991C17 6.13391 13.866 2.99991 10 2.99991ZM10.5 10.9999C10.7761 10.9999 11 11.2238 11 11.4999C11 11.7454 10.8231 11.9495 10.5899 11.9919L10.5 11.9999H7.5C7.22386 11.9999 7 11.7761 7 11.4999C7 11.2544 7.17687 11.0503 7.41012 11.008L7.5 10.9999H10.5ZM12.5 7.99991C12.7761 7.99991 13 8.22377 13 8.49991C13 8.74537 12.8231 8.94952 12.5899 8.99185L12.5 8.99991H7.5C7.22386 8.99991 7 8.77605 7 8.49991C7 8.25445 7.17687 8.0503 7.41012 8.00796L7.5 7.99991H12.5Z" fill="#8A97AD"/>
                                    </svg>
                                </span>
                                <span className='title'>
                                    Hair consists of dead, keratinized cells
                                </span>
                            </button>
                            <button className="gr-btn icon-btn-18 icon-btn" aria-label="Options">
                                <MoreVertIcon/>
                            </button>
                        </li>
                        <li>
                            <button className="chat-history-result-item">
                                <span className='icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 1.99991C14.4183 1.99991 18 5.58163 18 9.99991C18 14.4182 14.4183 17.9999 10 17.9999C8.72679 17.9999 7.49591 17.7018 6.38669 17.1392L6.266 17.0749L2.62109 17.985C2.31127 18.0624 2.02622 17.8368 2.00131 17.5437L2.00114 17.4623L2.01493 17.3786L2.925 13.7349L2.86169 13.6152C2.4066 12.7185 2.12433 11.7421 2.03275 10.7282L2.00738 10.3462L2 9.99991C2 5.58163 5.58172 1.99991 10 1.99991ZM10 2.99991C6.13401 2.99991 3 6.13391 3 9.99991C3 11.2169 3.31054 12.3877 3.89352 13.4248C3.94046 13.5083 3.9621 13.6029 3.95692 13.6972L3.94274 13.7911L3.187 16.8119L6.21104 16.0582C6.27294 16.0428 6.33662 16.0395 6.39873 16.0478L6.4903 16.069L6.57701 16.1074C7.61362 16.6897 8.7837 16.9999 10 16.9999C13.866 16.9999 17 13.8659 17 9.99991C17 6.13391 13.866 2.99991 10 2.99991ZM10.5 10.9999C10.7761 10.9999 11 11.2238 11 11.4999C11 11.7454 10.8231 11.9495 10.5899 11.9919L10.5 11.9999H7.5C7.22386 11.9999 7 11.7761 7 11.4999C7 11.2544 7.17687 11.0503 7.41012 11.008L7.5 10.9999H10.5ZM12.5 7.99991C12.7761 7.99991 13 8.22377 13 8.49991C13 8.74537 12.8231 8.94952 12.5899 8.99185L12.5 8.99991H7.5C7.22386 8.99991 7 8.77605 7 8.49991C7 8.25445 7.17687 8.0503 7.41012 8.00796L7.5 7.99991H12.5Z" fill="#8A97AD"/>
                                    </svg>
                                </span>
                                <span className='title'>
                                    What determines skin color
                                </span>
                            </button>
                            <button className="gr-btn icon-btn-18 icon-btn" aria-label="Options">
                                <MoreVertIcon/>
                            </button>
                        </li>
                    </ol>
                </div>
            </div>
		</section>
    )
}
export default ChatHistory