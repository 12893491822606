/** @format */

import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { useRef, useState, useEffect } from 'react';

const ChatTip = (props) => {
	const userData = useSelector((state) => state.user);

	const [tipMessage, setTipMessage] = useState('');
	const [hideChatTip, setHideChatTip] = useState(false);

	const container = useRef();

	useEffect(() => {
		setHideChatTip(false);

		if (userData.chatHistory.length <= 1) {
			// container.current.classList.add("fadeOut")
		} else {
			container.current.classList.replace('fadeIn', 'fadeOut');
		}

		setTimeout(() => {
			if (userData.chatHistory.length > 0) {
				let currentMessage =
					userData.chatHistory[userData.chatHistory.length - 1];
				if (currentMessage.user === 'welcome') {
					setTipMessage(currentMessage.popup);
					container.current.classList.replace('fadeOut', 'fadeIn');
				}
			}
		}, 150);
	}, [userData.chatHistory]);

	return (
		<div
			className={`chat-tip fadeOut ${hideChatTip ? 'hidden' : ''}`}
			ref={container}
		>
			<button
				className='close-btn'
				onClick={(e) => {
					props.close(e);
				}}
			>
				<CloseIcon />
			</button>
			<div
				role='button'
				onClick={props.open}
				className='load-prompt'
				dangerouslySetInnerHTML={{
					__html: tipMessage,
				}}
			/>
			<a
				role='button'
				onClick={props.open}
			>
				Chat with me
			</a>
		</div>
	);
};

export default ChatTip;
