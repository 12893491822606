/** @format */

const Skeleton = (props) => {
	let { variant, width, height } = props;
	width = width ? (width.indexOf('%') > -1 ? width : width + 'px') : '100%';
	return (
		<span
			variant={variant}
			style={{
				width: width,
				height:
					variant === 'circular'
						? width + 'px'
						: height
						? height + 'px'
						: '1em',
			}}
			className='skeleton'
			aria-hidden='true'
		></span>
	);
};

export default Skeleton;
