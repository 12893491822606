/** @format */
import { useState } from 'react';
import SearchResults from './SearchResults';
import SearchLoading from './SearchLoading';
import SearchDefault from './SearchDefault';

const SearchContent = (props) => {
	const [searchMode, setSearchMode] = useState(false);
	const [loading, setLoading] = useState(false);

	const submitSearch = (event) => {
		event.preventDefault();
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
			setSearchMode(true);
		}, 3000);
	};

	return (
		<section className='search-content'>
			<form onSubmit={(e) => submitSearch(e)}>
				<input type='search' />
			</form>
			{loading ? (
				<SearchLoading />
			) : !searchMode ? (
				<SearchDefault />
			) : (
				<SearchResults />
			)}
		</section>
	);
};

export default SearchContent;
