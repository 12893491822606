// State held component not using Redux
import React from 'react';

const AnswerOptionsMultiple = (props) => {
    
    return (
        <ul className="answer-options" id="answerOptions"  >
            {props.options.map((ansOption, index) => { 
                return(
                props.correctAnswer === index && props.showCorrect ?
                    <li key={`ansoption${index}`} className="correct-ans">
                        <svg focusable="false" className="icon-24 radio-correct-pl" aria-hidden="true">
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#multi-correct-24"></use>
                        </svg>
                        <span className="label">{ansOption.answer}</span>
                    </li>  
                :
                    <li key={`ansoption${index}`}
                        className={`${(props.showCorrect && props.correctAnswer === index) ? "correct-ans" : ""} ${(props.showCorrect) ? 'disabled': ''}`}>
                        <div className={`gr-checkbox ${props.showCorrect && props.correctAnswer === index ? "corrected" : ""} `}>
                            <input type="checkbox" 
                                id={`option${props.currentPart}${index}`} 
                                value={index}
                                onChange={(e) => props.selectAnswer(e)}
                                disabled={props.showCorrect}
                            />
                            <label htmlFor={`option${props.currentPart}${index}`}>{ansOption.answer}</label>
                            <span>
                                <svg focusable="false" className="icon-24 checkbox-select" aria-hidden="true">
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#multi-correct-24"></use>
                                </svg>
                                <svg focusable="false" className="icon-24 checkbox-correct" aria-hidden="true">
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#multi-correct-24"></use>
                                </svg>
                                <svg focusable="false" className="icon-24 checkbox-wrong" aria-hidden="true">
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#multi-incorrect-24"></use>
                                </svg>
                            </span>
                        </div>
                    </li>
                )
            })}
        </ul>
    );
}

export default AnswerOptionsMultiple;
