import React, {useState} from 'react';

const QuestionStem = (props) => {	

    return (
        <div className={`question-stem`}>
            
            <div className='question-stem-header'>
                <h2 className='question-title'>{props.title}</h2>
                <div className='question-metadata'>
                    
                <span>
                    {props.totalParts === 1 ? "1 part" : props.totalParts + " parts"}
                </span>
                <span className='divider'/>
                <span>
                {props.pointsEarned}/{props.totalPoints} {props.totalPoints > 1 ? "points" : "point"} 
                </span>
                    </div>
            </div>
            {/* Will need to revise for multipart with shared stem if needed in future */}
        </div>
    );
}

export default QuestionStem;
