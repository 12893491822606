import React from "react";

const AnswerInput = (props) => {

    return (
        <div className={"input-row"}>
            {props.data.before ? <div className="before" dangerouslySetInnerHTML={{__html: props.data.before}}/> : <></>}

            {props.showCorrect ? (
								<div
									className={'input-container correct'}
									style={{ display: 'inline', maxWidth: '100px' }}
								>
									<svg focusable="false" className="icon-18" aria-hidden="true">
										<use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#correct-18"></use>
									</svg>
									<input
										type={'number'}
										className={'gr-input'}
										id={'assessment2-' + props.currentPart}
										style={{ display: 'inline', maxWidth: '100px' }}
										disabled={true}
										defaultValue={props.correctAnswer}
									/>
								</div>
							) : (
								<div
									className={'input-container'}
									style={{ display: 'inline', maxWidth: '100px' }}
								>
									<input
										type={'number'}
										className={'gr-input'}
										id={'assessment2-' + props.currentPart}
										style={{ display: 'inline', maxWidth: '100px' }}
										onChange={props.selectAnswer}
									/>
								</div>
							)}


            {props.data.after ? <div className="after" dangerouslySetInnerHTML={{__html: props.data.after}}/> : <></>}



        </div>
    )

}
export default AnswerInput