import React, {forwardRef, useState, useEffect}  from 'react';
import AnswerOptions from './AnswerOptions';
import AnswerOptionsMultiple from './AnswerOptionsMultiple';
import AnswerInput from './AnswerInput';
import Response from './Response';
import placeholder from "../assets/images/dnd_placeholder.png";
import ImageLabelling from './ImageLabelling';
import { useDispatch, useSelector } from 'react-redux';
import { attemptItem, completeItem } from '../redux/userSlice';

const QuestionPart = forwardRef((props, ref) => {
    const userData = useSelector(state => state.user);
    const dispatch = useDispatch();

    let alpha = ['A', 'B', 'C', 'D', 'E'];
    let tries = props.data.tries ? props.data.tries : 1;
    let correctAnswer =  props.data.answerOptions ? props.data.answerOptions.indexOf(element => element.correct) : 0;

    const [questionState, setQuestionState] = useState({
        answerValue: "",    // User's chosen answer
btnSubmitDisabled: true,  // Disables the Submit buttion
btnShowAnswerDisabled: false, // Disables the Show Answer button
questionDisabled: false, // Disables the question
        showAnswer: false, // User has chosen to show the answer instead of attempting to answer
    showResponse: false, // Enables showing of the <Response />
    correctAnswerGiven: false,  // Whether the user got the question correct or not
    responseArray: [],    // Array holding the chosen answer's response
responseDetailsArray: []    // Array holding the chosen answer's detailed response - hidden behind a toggle button
});

    useEffect(() => {
        let thisItem = userData.assignments[0].items.filter(f => f.itemid === props.itemid)[0];
        if (thisItem.attempts.length > 0) {
            //get last attempt
            let lastAttempt = thisItem.attempts[thisItem.attempts.length - 1];

            //get feedback
            let feedback = '';
            if (props.data.answerOptions) {

                feedback = props.data.answerOptions[lastAttempt.value].feedback ? props.data.answerOptions[lastAttempt.value].feedback : ''
            }
            setQuestionState({...questionState, 
                answerValue: lastAttempt.value,
                correctAnswerGiven:lastAttempt.correct, 
                btnSubmitDisabled:true, 
                showResponse:true,
                responseArray: feedback,
                responseDetailsArray: feedback
            })
        } else {
            setQuestionState({
                answerValue: "",    // User's chosen answer
        btnSubmitDisabled: true,  // Disables the Submit buttion
        btnShowAnswerDisabled: false, // Disables the Show Answer button
        questionDisabled: false, // Disables the question
                showAnswer: false, // User has chosen to show the answer instead of attempting to answer
            showResponse: false, // Enables showing of the <Response />
            correctAnswerGiven: false,  // Whether the user got the question correct or not
            responseArray: [],    // Array holding the chosen answer's response
        responseDetailsArray: []    // Array holding the chosen answer's detailed response - hidden behind a toggle button
        })
        }
    },[props.data, userData])


function onChangeValue(event) {
    setQuestionState({
      ...questionState,
      answerValue: event.target.value,
      btnSubmitDisabled: false
    });
    console.log(event.target.value)
  }
function handleSubmit(event) {
    event.preventDefault();

    let slectedChoice;

    if (props.data.answerOptions) {
    // Check correctness, goes by answer index
    slectedChoice = props.data.answerOptions[questionState.answerValue];
    var state = false;

    if (slectedChoice.correct === true) {
        dispatch(completeItem({itemid: props.itemid}))
        state = true;
      } else {
        state = false;
  
      }
    } else {
        state=true
        slectedChoice = props.data
        dispatch(completeItem({itemid: props.itemid}))
    }
    setQuestionState({
      ...questionState,
      btnSubmitDisabled: true,
      btnShowAnswerDisabled: true,
      questionDisabled: true,
      showResponse: true,
      correctAnswerGiven: state,
      responseArray: slectedChoice.feedback,
      responseDetailsArray: slectedChoice.feedback
    });

    console.log("add item attempt", props.data)
    dispatch(attemptItem({itemid: props.itemid, attempt: {value: questionState.answerValue, correct: state}}))
  }
  
    return (
        <div className="question-part-group">
            <div className="question-part-header">
                <h3 className='question-part-title'>Part {alpha[0]}</h3>
                
            </div>
            <fieldset className="question-part">
            <legend className="question-stem" dangerouslySetInnerHTML={{ __html: props.data.stem }} />
            {props.data.instructions && <div className='question-instructions' dangerouslySetInnerHTML={{ __html: props.data.instructions }}/>}
                {props.data.answerOptions ? 
                    <>
                        {/* <div className="question" dangerouslySetInnerHTML={{__html: props.data.details}}></div> */}
                        {props.data.type === "input" ? 
                            <AnswerInput selectAnswer={props.selectAnswer} correctAnswer={correctAnswer} currentPart={props.currentPart}
                            showCorrect={props.graded} data={props.data.answerOptions[0]} />
                        : props.data.type === "multi" ?
                        
                            <AnswerOptionsMultiple
                                options={props.data.answerOptions} 
                                selectAnswer={(event) => onChangeValue(event)}
                                selectedAnswers={props.selectedAnswers}
                                currentPart={props.currentPart}
                                correctAnswer={correctAnswer}
                                showCorrect={questionState.correctAnswerGiven}
                            />

                    
                        : 
                        <AnswerOptions 
                            options={props.data.answerOptions} 
                            selectAnswer={(event) => onChangeValue(event)}
                            selectedAnswers={props.selectedAnswers}
                            currentPart={props.currentPart}
                            correctAnswer={correctAnswer}
                            questionState={questionState}
                            showCorrect={questionState.correctAnswerGiven}
                            random={props.random}
                        />
                        }
                        
                    </>
                    : props.data.dragOptions ? 

                    <ImageLabelling options={props.data.dragOptions} 
                        showCorrect={questionState.correctAnswerGiven}
                        score={() => setQuestionState({
                             ...questionState,
                             answerValue: 0,
                             btnSubmitDisabled: false
                           })} />
                    
                        // <div className='part-image' onClick={() => setQuestionState({
                        //     ...questionState,
                        //     answerValue: 0,
                        //     btnSubmitDisabled: false
                        //   })}>
                        //     <img src={placeholder} alt={"placeholder"}/>
                        // </div>
                : 
                    <div className="question-with-img">
                        {props.data.details && 
                            <div className="question" dangerouslySetInnerHTML={{__html: props.data.details}}></div>
                        }
                        <div className="part-image" onClick={props.selectAnswer}>
                            <img src={process.env.PUBLIC_URL + "/images/" + props.data.image} alt="" />
                        </div>
                    </div>

                }
                <div className='question-actions'>
                <button className='gr-btn primary' onClick={handleSubmit} disabled={questionState.btnSubmitDisabled}>Check answer</button>
                </div>
                {
                
                questionState.showResponse ?
                <Response ref={ref} type={questionState.correctAnswerGiven ? 'correct' : 'incorrect'} feedback={questionState.responseArray}/>
                    
                    : null
                }
            </fieldset>
        </div>
    );
});


export default QuestionPart;
