import { Icon } from "@mui/material";
import React, {forwardRef, useState} from "react";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
const Response = forwardRef((props, ref) => {
    const [open, setOpen] = useState(true);

    return (
        
        <div className={`response-container ${props.type}`} ref={ref}>
            {props.type === 'correct' ? <>
                <div className="response-btn">
                    <h4 className="title">
                        {props.type === 'correct' ? "Correct!" : "That’s incorrect"}
                        
                    </h4>
                </div>
                <div className="response" dangerouslySetInnerHTML={{__html: props.feedback}}>
                    
                </div>
            </> : <>
                <button className="response-btn" aria-expanded={open} onClick={()=> setOpen(!open)}>
                <h4 className="title">
                    {props.type === 'correct' ? "Correct!" : "Try again"}
                    
                </h4>
                <span className="icon">
                    <ExpandLessIcon/> 
                </span>
            </button>
            {open && 
            <div className="response" dangerouslySetInnerHTML={{__html: props.feedback}}>
                
            </div> }
            </>}
            
        </div>
    )
})

export default Response