/** @format */

import React, { useEffect, useRef, useState } from 'react';
import OpenAI from 'openai';
import iconClose from '../assets/images/icon_close_gradient.png';
import DrawerViewMenu from './DrawerViewMenu';
import prompts from '../assets/data/chat/prompts.json';
import SearchContent from './SearchContent';
import ConfettiExplosion from 'react-confetti-explosion';

import { useSelector, useDispatch } from 'react-redux';
import {
	addMessageToHistory,
	updateLastMessage,
	removeMessageFromHistory,
} from '../redux/userSlice';
import ChatMCQ from './ChatMCQ';
import ChatTabs from './ChatTabs';
import ChatHistory from './ChatHistory';

// Initialize the OpenAI client
const openai = new OpenAI({
	apiKey: process.env.REACT_APP_OPENAI_API_KEY,
	dangerouslyAllowBrowser: true,
});

const ChatbotDrawer = (props) => {
	const userData = useSelector((state) => state.user);
	const [assistant, setAssistant] = useState(
		'asst_7xx1JDdodYcm1rNqZigqk6K9'
	); /* ID from the OpenAI Assistant for summary. 'asst_k1fJy9haum4RUIEJkXhkZeho' is the key for the explain assistant */
	//const [enter, setEnter] = useState(true);
	const [coachMeCount, setCoachMeCount] = useState(0);
	const [nosCoachMe, setNosCoachMe] = useState(2);
	const [thread, setThread] = useState(null);
	const [output, setOutput] = useState('');
	const [isStreaming, setIsStreaming] = useState(false);
	const [welcomePrompt, setWelcomePrompt] = useState(null);

	const [textInput, setTextInput] = useState('');
	const [isExploding, setIsExploding] = useState(false);
	const [latestPrompt, setLatestPrompt] = useState(null);

	const input = useRef();
	const messagesEndRef = useRef();

	useEffect(() => {
		setTimeout(() => {
			setIsExploding(true);
		}, 300);
	}, []);

	const dispatch = useDispatch();

	/* Submit chat input form */
	const submitChatInput = (e) => {
		e.preventDefault();

		sentChat(textInput.toLowerCase());

		if (textInput.toLowerCase() !== 'yes' && textInput.toLowerCase() !== 'no') {
			if (
				textInput.indexOf('explain') > -1 ||
				textInput.indexOf('define') > -1
			) {
				setAssistant('asst_k1fJy9haum4RUIEJkXhkZeho');
				interactWithSumamrizeAssistant(assistant, textInput);
			} else {
				if (textInput.indexOf('Summarize this') > -1) {
					let userInput = `Summarize section about ${props.item.name}`;
					interactWithSumamrizeAssistant(assistant, userInput);
				} else {
					interactWithSumamrizeAssistant(assistant, textInput);
				}
			}
		}
	};

	/* To load welcome message on overview page */
	useEffect(() => {
		if (props.pageType === 'overview') {
			let newMessage;
			if (props.assignmentComplete) {
				let reviewPrompt = prompts.filter((a) => a.screenID === 'review');
				newMessage = {
					user: 'review',
					text: reviewPrompt[0].scenarios[0].prompts[0].question,
					itemId: 'review',
				};
				// setLatestPrompt(reviewPrompt)
			} else {
				let overviewPrompt = prompts.filter((a) => a.screenID === 'overview');

				newMessage = {
					user: 'overview',
					text: overviewPrompt[0].scenarios[0].prompts[0].question,
					itemId: 'overview',
				};
				// setLatestPrompt(overviewPrompt)
			}
			dispatch(addMessageToHistory(newMessage));
		}
	}, [props.pageType]);

	useEffect(() => {
		if (messagesEndRef.current) {
			messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, [userData.chatHistory]);

	/* Interact with the summarize assistant in OpenAI */
	const interactWithSumamrizeAssistant = async (assistantId, userMessage) => {
		// addMessagetoHistory('user', userMessage);
		//console.log('user message', openAiPrompts);

		//Create a thread
		const thread = await openai.beta.threads.create();
		//console.log('Thread created with id:', thread.id);

		setThread(thread);

		//console.log('Creating message...');
		await openai.beta.threads.messages.create(thread.id, {
			role: 'user',
			content: userMessage,
		});
		console.log('Message created');
		let userEntry = { user: 'user', text: userMessage };
		let newEntry = { user: 'Assistant', text: '' };

		let copiedHistory = [...userData.chatHistory, userEntry, newEntry];

		let entryToTarget = { ...copiedHistory[copiedHistory.length - 1] };

		//addMessagetoHistory('user', userMessage);
		setIsStreaming(true);

		const run = new Promise((resolve, reject) => {
			console.log('Starting stream...');
			openai.beta.threads.runs
				.stream(thread.id, {
					assistant_id: assistantId,
				})
				.on('textCreated', (text) => {
					//console.log('Text created:', text);
					//setOutput((prevOutput) => `${prevOutput}\nassistant > `);
				})
				.on('textDelta', (textDelta, snapshot) => {
					// console.log('Text delta:', textDelta);
					setOutput((prevOutput) => `${prevOutput}${textDelta.value}`);

					entryToTarget.text = entryToTarget.text + textDelta.value;
					//copiedHistory[copiedHistory.length - 1] = entryToTarget;
					/*console.log(entryToTarget);*/
					dispatch(updateLastMessage(textDelta.value));
				})
				.on('toolCallCreated', (toolCall) => {
					console.log('Tool call created:', toolCall);
					/*setOutput(
						(prevOutput) => `${prevOutput}\nassistant > ${toolCall.type}\n\n`
					);*/
				})
				.on('toolCallDelta', (toolCallDelta, snapshot) => {
					console.log('Tool call delta:', toolCallDelta);
					if (toolCallDelta.type === 'file_search') {
						if (toolCallDelta.file_search.input) {
							setOutput(
								(prevOutput) =>
									`${prevOutput}${toolCallDelta.file_search.input}`
							);
						}
						if (toolCallDelta.file_search.outputs) {
							setOutput((prevOutput) => `${prevOutput}\noutput >\n`);
							toolCallDelta.file_search.outputs.forEach((output) => {
								if (output.type === 'logs') {
									setOutput((prevOutput) => `${prevOutput}\n${output.logs}\n`);
								}
							});
						}
					}
				})
				.on('messageDone', () => {
					setIsStreaming(false);
					//addMessagetoHistory('assistant', output);
					// addMessagetoHistory('Assistant', `${output}`); //for now, fix so it streams correctly
				})
				.on('error', (error) => {
					console.log('Error:', error);
					setIsStreaming(false);
				});
		});
	};

	const getWelcomePrompt = () => {
		console.log('Get welcome prompt');
		let currentID = props.item.itemid ? props.item.itemid : props.pageType,
			currentPrompts = prompts.filter((f) => f.screenID === currentID);

		if (currentPrompts.length > 0) {
			let welcomePrompts = currentPrompts[0].scenarios.filter(
				(f) => f.type === 'load'
			);

			if (welcomePrompts[0].prompts.length > 0) {
				let messageToStore = {};
				welcomePrompts[0].prompts.forEach((prompt) => {
					setTimeout(() => {
						console.log(prompt);
						setWelcomePrompt(prompt);

						// if (prompt.buttons) {
						// 	console.log('has buttons');
						// 	// addMessagetoHistory('welcome', prompt.question, prompt.buttons);
						// } else {
						// 	// addMessagetoHistory('welcome', prompt.question);
						// }
					}, 1000);
				});
			}
		}
	};

	/* Coach Me ---*/
	const coachMe = (text) => {
		let currentID = props.item ? props.item.itemid : props.pageType,
			currentPrompts = prompts.filter((f) => f.screenID === currentID);


		addMessagetoHistory('user', { question: text });
		if (currentPrompts.length > 0) {
			let coachMePrompts = currentPrompts[0].scenarios.filter(
				(f) => f.type === 'coachme'
			)[0];

			setNosCoachMe(coachMePrompts.prompts.length);

			if (coachMeCount < coachMePrompts.prompts.length) {
				let currentCoachMePrompt = coachMePrompts.prompts[coachMeCount];
				console.log(currentCoachMePrompt);
				setTimeout(
					// dispatch(addMessageToHistory(newMessage));
					() => addMessagetoHistory('coachme', currentCoachMePrompt),
					500
				);
			}
		}

		setCoachMeCount((coachMeCount) => coachMeCount + 1);
	};

	useEffect(() => {
		if (props.pageType === 'assignment') {
			getWelcomePrompt();
		}
		setCoachMeCount(0);
	}, [props.item]);

	const addMessagetoHistory = (type, message) => {
		console.log(message);
		let newMessage;
		let itemID = props.item?.itemid || props.pageType;

		if (itemID) {
			newMessage = {
				user: type,
				text: message.question,
				itemId: itemID,
				itemNum: props.currentItem,
				itemName: props.item?.name || props.pageType,
				buttons: message.buttons ? message.buttons : undefined,
				mcq: message.mcq ? message.mcq : undefined,
				tabs: message.tabs ? message.tabs : undefined,
			};
		} else if (actions !== undefined) {
			newMessage = {
				user: type,
				text: message.question,
				buttons: actions,
				itemName: props.item.name,
				itemNum: props.currentItem,
			};
		} else {
			newMessage = {
				user: type,
				text: message.question,
				itemName: props.item.name,
			};
		}

		dispatch(addMessageToHistory(newMessage));
	};

	const removeLastMessageFromHistory = () => {
		//remove 'no' and last assistant message
		dispatch(removeMessageFromHistory(2));
		// props.updateChatHistory('remove');
	};

	const sentChat = (message) => {
		console.log(message);

		// let message = input.current.value;
		addMessagetoHistory('user', { question: message });

		//if last item in history is the welcome message, AND message is a yes
		let assistantMsgs = userData.chatHistory.filter((f) => f.user !== 'user'),
			lastHistoryMsg = assistantMsgs[assistantMsgs.length - 1];

		//console.log(lastHistoryMsg);
		//console.log(welcomePrompt.question);
		/*if (welcomePrompt.question === lastHistoryMsg.text) {
			console.log('same as welcome');
			console.log(message);*/
		//if yes
		if (message.toLowerCase().includes('yes')) {
			console.log('i said yes');
			let messageSource;
			if (latestPrompt?.response) {
				//go to next child prompt
				messageSource = latestPrompt;
			} else {
				messageSource = welcomePrompt;
			}
			let message = messageSource.response.filter((f) => f.type === 'yes')[0];
			//how to handle response > repsonse > response
			setLatestPrompt(message);
			setTimeout(
				() =>
					addMessagetoHistory('assistant', {
						question: message.content,
						buttons: message.buttons ? message.buttons : undefined,
						tabs: message.tabs ? message.tabs : undefined,
					}),
				1000
			);
		} else if (message.toLowerCase().includes('no')) {
			console.log('remove messages');
			let messageObj = welcomePrompt.response.filter((f) => f.type === 'no');
			let message = messageObj[0].content;
			setTimeout(
				() => addMessagetoHistory('assistant', { question: message }),
				1000
			);
			// setTimeout(() => removeLastMessageFromHistory(), 1000);
		}
		//if anything else initiate chat gpt i think?
		//}

		//clear input
		input.current.value = '';
	};
	/*const initiateUserChatAndResponse = () => {
		//if we want to have the prompts as buttons and trigger a user message
		addMessagetoHistory(
			'user',
			welcomePrompt.userResponse
				? welcomePrompt.userResponse
				: welcomePrompt.question
		);
		setTimeout(
			() =>
				addMessagetoHistory(
					'assistant',
					welcomePrompt.response.type === 'text'
						? welcomePrompt.response.content
						: 'Insert ' + welcomePrompt.response.type
				),
			1200
		);
	}; */

	return (
		<>
			<div
				className={`chatbot-drawer
				
				${
					props.drawerMode === 'overlay'
						? 'overlay'
						: props.drawerMode === 'floating'
						? 'overlay'
						: 'sidebyside'
				}`}
			>
				<div className='drawer-header'>
					<h2>
						{props.drawerType === 'search'
							? 'Search'
							: props.drawerType === 'history'
							? 'Chat history'
							: 'Tutor'}
					</h2>
					<div className='drawer-header-actions'>
						<DrawerViewMenu
							drawerMode={props.drawerMode}
							changeDrawerMode={props.changeDrawerMode}
						/>

						<button
							className='close-btn'
							onClick={props.close}
						>
							<img
								src={iconClose}
								alt=''
							/>
						</button>
					</div>
				</div>
				<div
					className={`drawer-body ${props.drawerType} ${
						props.pageType === 'overview' ? 'overview-page' : ''
					}`}
				>
					{props.drawerType === 'search' ? (
						<SearchContent />
					) : props.drawerType === 'history' ? (
						<ChatHistory />
					) : (
						<>
							{props.assignmentComplete && isExploding && (
								<ConfettiExplosion
									particleCount={400}
									force={0.8}
									duration={3000}
									width={1600}
									zIndex={11112}
								/>
							)}
							<div className='response'>
								{userData.chatHistory?.length > 0 &&
									userData.chatHistory.map((item, index) => {
										let previousId =
											index > 0
												? userData.chatHistory[index - 1].itemId
												: undefined;
										return (
											<div
												className={`message from-${item.user.toLowerCase()}`}
												key={'message' + index}
											>
												{item.itemId &&
												item.itemId !== previousId &&
												item.itemName !== undefined ? (
													<div className='item-id'>
														<button
															className='assignment-item-link'
															onClick={() => props.gotoItem(item.itemNum)}
														>
															{item.itemName}
														</button>
													</div>
												) : (
													<></>
												)}
												<div
													className='load-prompt'
													dangerouslySetInnerHTML={{ __html: item.text }}
												/>
												{item.mcq && <ChatMCQ data={item.mcq} />}
												{item.tabs && <ChatTabs data={item} />}
												{/* {item.buttons &&
													index === userData.chatHistory.length - 1 && (
														<div className='from-welcome-actions'>
															<button
																className='chat-btn'
																onClick={() => sentChat(item.buttons[0])}
															>
																{item.buttons[0]}
															</button>
															<button
																className='chat-btn'
																onClick={() => sentChat(item.buttons[1])}
															>
																{item.buttons[1]}
															</button>
														</div>
													)} */}
											</div>
										);
									})}
								<div ref={messagesEndRef}></div>
							</div>

							<div className='chat'>
								{props.assignmentComplete ? (
									<></>
								) : (
									<div className='gpt-actions'>
										{userData.chatHistory[userData.chatHistory.length - 1]?.buttons?.length > 0 ? <>
											{userData.chatHistory[userData.chatHistory.length - 1].buttons.map((button, index) => {
												return (
													<button className='chat-btn'
														onClick={welcomePrompt?.coachme ? () => coachMe(button) : () => sentChat(button)}
													>{button}</button>
												)
											})}
										</> : welcomePrompt?.coachme ?
										<button
											className='chat-btn'
											onClick={() => coachMe(welcomePrompt.coachme ? welcomePrompt.coachme : "Walk me through it")}
											hidden={coachMeCount >= nosCoachMe || isStreaming}
										>
											{welcomePrompt.coachme ? welcomePrompt.coachme : "Walk me through it"}
										</button>
										
									:(
										<></>
									)  }
									</div>
								) }
								<form
									className='input-container'
									onSubmit={(e) => submitChatInput(e)}
								>
									<input
										type='text'
										placeholder={`${
											props.pageType === 'overview'
												? 'Ask me anything...'
												: 'Ask your questions...'
										}`}
										ref={input}
										defaultValue={textInput}
										onKeyUp={(e) => setTextInput(e.target.value)}
									/>
									<div className='chat-actions'>
										<button aria-label='microphone'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='24'
												height='24'
												viewBox='0 0 24 24'
												fill='none'
											>
												<path d='M18.25 10.9961C18.6297 10.9961 18.9435 11.2782 18.9932 11.6443L19 11.7461V12.2461C19 15.8055 16.245 18.7214 12.751 18.9778L12.75 21.2461C12.75 21.6603 12.4142 21.9961 12 21.9961C11.6203 21.9961 11.3065 21.7139 11.2568 21.3479L11.25 21.2461L11.25 18.9779C7.83323 18.7277 5.12283 15.9341 5.00406 12.4824L5 12.2461V11.7461C5 11.3319 5.33579 10.9961 5.75 10.9961C6.1297 10.9961 6.44349 11.2782 6.49315 11.6443L6.5 11.7461V12.2461C6.5 15.0731 8.73445 17.3782 11.5336 17.4917L11.75 17.4961H12.25C15.077 17.4961 17.3821 15.2616 17.4956 12.4625L17.5 12.2461V11.7461C17.5 11.3319 17.8358 10.9961 18.25 10.9961ZM12 1.99609C14.2091 1.99609 16 3.78695 16 5.99609V11.9961C16 14.2052 14.2091 15.9961 12 15.9961C9.79086 15.9961 8 14.2052 8 11.9961V5.99609C8 3.78695 9.79086 1.99609 12 1.99609ZM12 3.49609C10.6193 3.49609 9.5 4.61538 9.5 5.99609V11.9961C9.5 13.3768 10.6193 14.4961 12 14.4961C13.3807 14.4961 14.5 13.3768 14.5 11.9961V5.99609C14.5 4.61538 13.3807 3.49609 12 3.49609Z' />
											</svg>
										</button>
										<button
											type='submit'
											aria-label='Send chat'
											disabled={textInput.length === 0}
										>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='24'
												height='24'
												viewBox='0 0 24 24'
												fill='none'
											>
												<path d='M12.8147 12.1933L5.28344 13.4485C5.10705 13.4779 4.95979 13.5992 4.89723 13.7668L2.29933 20.7242C2.05066 21.3636 2.72008 21.9737 3.33375 21.6668L21.3337 12.6668C21.8865 12.3904 21.8865 11.6016 21.3337 11.3252L3.33375 2.32518C2.72008 2.01835 2.05066 2.62839 2.29933 3.26784L4.89723 10.2252C4.95979 10.3928 5.10705 10.5141 5.28344 10.5435L12.8147 11.7987C12.9236 11.8169 12.9972 11.9199 12.9791 12.0289C12.965 12.1132 12.899 12.1792 12.8147 12.1933Z' />
											</svg>
										</button>
									</div>
								</form>
							</div>
						</>
					)}
				</div>
				{props.drawerType === 'chat' && (
					<div className='drawer-footer'>
						{"Don't share personal info. Check for mistakes. "}
						<span style={{ whiteSpace: 'nowrap' }}>
							See our <a href='#!'>FAQs</a>
						</span>
						.
					</div>
				)}
			</div>
		</>
	);
};

export default ChatbotDrawer;
