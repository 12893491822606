/** @format */

import { createSlice } from '@reduxjs/toolkit';
import initial from '../assets/data/user/user.json';

const initialState = initial[0];

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		resetScores: (state) => {
			//will need to adjust when more assignments
			state.assignments[0].items.forEach((item) => {
				item.viewed = false;
				item.completed = false;
				if (item.attempts) {
					item.attempts = [];
				}
			});
			return state;
		},
		viewedItem: (state, action) => {
			state.assignments[0].items.map((item) => {
				item.itemid === action.payload.itemid ? (item.viewed = true) : item;
			});
		},
		completeItem: (state, action) => {
			console.log(action.payload);
			state.assignments[0].items.map((item) => {
				item.itemid === action.payload.itemid ? (item.completed = true) : item;
			});
		},
		attemptItem: (state, action) => {
			state.assignments[0].items.map((item) => {
				console.log(item.itemid);
				item.itemid === action.payload.itemid
					? item.attempts.push(action.payload.attempt)
					: item;
			});
		},
		addMessageToHistory: (state, action) => {
			//Check if a welcome message for this item already exists before adding another instance
			if (
				action.payload.user === 'welcome' ||
				action.payload.user === 'overview' ||
				action.payload.user === 'review'
			) {
				let currId = action.payload.itemId;
				let currIdItems = state.chatHistory.filter((a) => a.itemId === currId);
				if (currIdItems.length == 0) {
					state.chatHistory.push(action.payload); // Adds a message to the chat history.
				}
			} else {
				state.chatHistory.push(action.payload); // Adds a message to the chat history.
			}
		},
		updateLastMessage: (state, action) => {
			if (
				state.chatHistory[state.chatHistory.length - 1].user.indexOf(
					'assistant'
				) > -1
			) {
				state.chatHistory[state.chatHistory.length - 1] = {
					...state.chatHistory[state.chatHistory.length - 1],
					text:
						state.chatHistory[state.chatHistory.length - 1].text +
						action.payload,
				};
			} else {
				state.chatHistory.push({ user: 'assistant', text: action.payload });
			}
		},
		removeMessageFromHistory: (state, action) => {
			//action payload is the number from end to remove (since no plus prev message)
			const chatHistoryLength = state.chatHistory.length;
			state.chatHistory = state.chatHistory.filter(
				(item, index) => index < chatHistoryLength - action.payload
			);
		},
	},
});

export const {
	resetScores,
	viewedItem,
	attemptItem,
	completeItem,
	addMessageToHistory,
	updateLastMessage,
	removeMessageFromHistory,
} = userSlice.actions;

export default userSlice.reducer;
