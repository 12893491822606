import React from "react";
import placeholder from "../assets/images/dnd_placeholder.png"
const ImageLabelling = (props) => {

    function onDrag(ev) {
        ev.dataTransfer.setData("text", ev.target.id)
    }
    const onDrop = (ev) => {
        ev.preventDefault();
        var data = ev.dataTransfer.getData("text");
        var target = ev.target.draggable ? ev.target.parentElement : ev.target;
        target.appendChild(document.querySelector("#"+data));

        //update answers
        props.score()
    }

    function allowDrop(ev) {
        ev.preventDefault();
      }

    return (
        <div className="labelling-activity">
            <div className="draggables">
                {props.options.map((item, index) => {
                    return (
                        <button className="drag-btn" draggable="true" onDragStart={onDrag} key={"drag" + index} id={"drag" + index} title={item}>{item}</button>
                    )
                })}
                
            </div>
            <div className="image-container">
                <div className="drop-areas">
                    <div className="drop-target" onDrop={(event) => onDrop(event)} onDragOver={allowDrop} id="drop1" style={{left:"36%",top:"0%"}}>{/*<!--Top left, A band -->*/}</div> 
                    <div className="drop-target" onDrop={(event) => onDrop(event)} onDragOver={allowDrop} id="drop2" style={{left:"54.5%",top:"0%"}}>{/*<!-- Top right, I band -->*/}</div>
                    <div className="drop-target" onDrop={(event) => onDrop(event)} onDragOver={allowDrop} id="drop3" style={{right:"1.5%",top:"10%"}}>{/*<!-- right top, Sarcolemma -->*/}</div>
                    <div className="drop-target" onDrop={(event) => onDrop(event)} onDragOver={allowDrop} id="drop4" style={{right:"1.5%",top:"27%"}}>{/*<!-- right second, triad -->*/}</div>
                    <div className="drop-target" onDrop={(event) => onDrop(event)} onDragOver={allowDrop} id="drop5" style={{right:"1.5%",top:"48.5%"}}>{/*<!-- right third, terminal cistern -->*/}</div>
                    <div className="drop-target" onDrop={(event) => onDrop(event)} onDragOver={allowDrop} id="drop6" style={{right:"1%",top:"75%"}}>{/*<!-- right last, myofibrils-->*/}</div>
                    <div className="drop-target" onDrop={(event) => onDrop(event)} onDragOver={allowDrop} id="drop7" style={{left:"0",top:"43%"}}>{/*<!-- left, T tubule-->*/}</div>
                </div>
                <img src={placeholder} alt="placeholder"/>
            </div>
        </div>
    )
}

export default ImageLabelling