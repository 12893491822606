/** @format */

const SearchDefault = () => {
	return (
		<div className='search-default'>
			<div className='search-previous'>
				<header>
					<h3>Previously searched</h3>
					<a href='javascript: void(0)'>Clear all</a>
				</header>
				<ul>
					<li>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='25'
							viewBox='0 0 24 25'
							fill='none'
						>
							<path
								d='M22 12.5C22 18.02 17.52 22.5 12 22.5C6.48 22.5 2 18.02 2 12.5C2 6.98 6.48 2.5 12 2.5C17.52 2.5 22 6.98 22 12.5Z'
								stroke='#78828A'
								stroke-width='1.5'
								stroke-linecap='round'
								stroke-linejoin='round'
							/>
							<path
								d='M15.71 15.68L12.61 13.83C12.07 13.51 11.63 12.74 11.63 12.11V8.01001'
								stroke='#78828A'
								stroke-width='1.5'
								stroke-linecap='round'
								stroke-linejoin='round'
							/>
						</svg>{' '}
						<p>tissue sheaths</p>
						<button className='icon-btn'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='15'
								height='15'
								viewBox='0 0 15 15'
								fill='none'
							>
								<path
									d='M3.12561 3.125L11.875 11.8744'
									stroke='#78828A'
									stroke-width='1.5'
									stroke-linecap='round'
									stroke-linejoin='round'
								/>
								<path
									d='M3.12505 11.8744L11.8745 3.125'
									stroke='#78828A'
									stroke-width='1.5'
									stroke-linecap='round'
									stroke-linejoin='round'
								/>
							</svg>
						</button>
					</li>
					<li>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='25'
							viewBox='0 0 24 25'
							fill='none'
						>
							<path
								d='M22 12.5C22 18.02 17.52 22.5 12 22.5C6.48 22.5 2 18.02 2 12.5C2 6.98 6.48 2.5 12 2.5C17.52 2.5 22 6.98 22 12.5Z'
								stroke='#78828A'
								stroke-width='1.5'
								stroke-linecap='round'
								stroke-linejoin='round'
							/>
							<path
								d='M15.71 15.68L12.61 13.83C12.07 13.51 11.63 12.74 11.63 12.11V8.01001'
								stroke='#78828A'
								stroke-width='1.5'
								stroke-linecap='round'
								stroke-linejoin='round'
							/>
						</svg>{' '}
						<p>levels of skeletal muscle</p>
						<button className='icon-btn'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='15'
								height='15'
								viewBox='0 0 15 15'
								fill='none'
							>
								<path
									d='M3.12561 3.125L11.875 11.8744'
									stroke='#78828A'
									stroke-width='1.5'
									stroke-linecap='round'
									stroke-linejoin='round'
								/>
								<path
									d='M3.12505 11.8744L11.8745 3.125'
									stroke='#78828A'
									stroke-width='1.5'
									stroke-linecap='round'
									stroke-linejoin='round'
								/>
							</svg>
						</button>
					</li>
					<li>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='25'
							viewBox='0 0 24 25'
							fill='none'
						>
							<path
								d='M22 12.5C22 18.02 17.52 22.5 12 22.5C6.48 22.5 2 18.02 2 12.5C2 6.98 6.48 2.5 12 2.5C17.52 2.5 22 6.98 22 12.5Z'
								stroke='#78828A'
								stroke-width='1.5'
								stroke-linecap='round'
								stroke-linejoin='round'
							/>
							<path
								d='M15.71 15.68L12.61 13.83C12.07 13.51 11.63 12.74 11.63 12.11V8.01001'
								stroke='#78828A'
								stroke-width='1.5'
								stroke-linecap='round'
								stroke-linejoin='round'
							/>
						</svg>{' '}
						<p>anatomy of a sarcomere</p>
						<button className='icon-btn'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='15'
								height='15'
								viewBox='0 0 15 15'
								fill='none'
							>
								<path
									d='M3.12561 3.125L11.875 11.8744'
									stroke='#78828A'
									stroke-width='1.5'
									stroke-linecap='round'
									stroke-linejoin='round'
								/>
								<path
									d='M3.12505 11.8744L11.8745 3.125'
									stroke='#78828A'
									stroke-width='1.5'
									stroke-linecap='round'
									stroke-linejoin='round'
								/>
							</svg>
						</button>
					</li>
				</ul>
			</div>

			<div className='search-tending'>
				<header>
					<h3>Trending with classmates</h3>
				</header>
				<div>
					<span>skeletal muscle cell</span>
					<span>tubules of SR</span>
					<span>reticulum</span>
					<span>skeletal muscle fibers</span>
					<span>triad relationships</span>
					<span>connective tissue</span>
				</div>
			</div>

			<div className='search-recent'>
				<header>
					<h3>Based on recent activity</h3>
				</header>
				<ul>
					<li className='card'>Tissue Sheaths</li>
					<li className='card'>Skeletal Muscle Fibers</li>
					<li className='card'>Sarcoplasmic Recticulum</li>
					<li className='card'>Sarcomere</li>
					<li className='card'>SR Tubules</li>
					<li className='card'>Triad Relationships</li>
				</ul>
			</div>
		</div>
	);
};

export default SearchDefault;
