const OverallScore = (props) => {
    let svgCircumference = 2 * 3.14 * 51,
        scorePercentage = parseInt((props.overallScore / props.totalPoints) * 100),
        arcLength = (parseInt(scorePercentage) * svgCircumference)/100,
        arcLengthDiff = svgCircumference - arcLength;

    return(        
        <div className={`overall-score ${props.itemsCompleted !== 0 ? 'in-progress' : ''} ${props.itemsCompleted === props.totalItems ? 'complete' : ''}`}>

            <div className="svg-item ">
                <svg width="100%" height="100%" viewBox="0 0 128 128" className="donut" preserveAspectRatio="xMinYMin meet">
                    <defs>
                        <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%"   stopColor="#01CBB7"/>
                            <stop offset="58%"  stopColor="#007899"/>
                            <stop offset="96%" stopColor="#005F79"/>
                        </linearGradient>
                    </defs>
                    <circle className="donut-hole" cx="64" cy="64" r="51" fill="transparent"></circle>
                    <circle className="donut-ring" cx="64" cy="64" r="51" fill="transparent" strokeWidth="16"></circle>
                    {props.overallScore > 0 &&
                        <circle className="donut-segment" cx="64" cy="64" r="51" fill="transparent" strokeWidth="8" strokeDasharray={`${arcLength} ${arcLengthDiff}`} strokeDashoffset="75"></circle>
                    }
                </svg>
                <div className="center-text"><span className="large">{props.overallScore > 0 ? scorePercentage : '--'}%</span></div>
            </div>
            <div>
                <h3>My Score</h3>
                <span>{props.overallScore}/{props.totalPoints} pts</span>
            </div>
        </div>
    )
}

export default OverallScore