import React, { useState} from "react";

const ChatTabs = (props) => {
    const [active, setActive] = useState(0);

    return (
        <div className="chat-tabs">
            <ul className="chat-tab-list" role="tablist">
                {props.data.tabs.map((item, index) => {
                    return (
                <li role="presentation">
                    <button className="chat-tab-item" role="tab" 
                    aria-selected={active===index}
                    onClick={() => setActive(index)}>{item.title}</button>
                </li>
                    )
                })}
                

            </ul>
            <div className="chat-tabs-panel" role="tabpanel" dangerouslySetInnerHTML={{__html: props.data.tabs[active].content}}></div>

        </div>
    )
}

export default ChatTabs