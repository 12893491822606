/** @format */

import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';
import EText from './EText';
import MainPage from './MainPage';

import './assets/css/styles.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<Provider store={store}>
		<HashRouter>
			<Routes>
				<Route
					path='/'
					element={<MainPage />}
				></Route>
				<Route
					path='a&p'
					element={<MainPage selectedData='aandp' />}
				></Route>
				<Route
					path='etext'
					element={<EText />}
				></Route>
			</Routes>
		</HashRouter>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
